import * as React from "react";
import { useHook as useDashboardHook } from "../dashboard/useHook";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import TablePagination from "@mui/material/TablePagination";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';
import {
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Table } from "react-bootstrap";
import { visuallyHidden } from "@mui/utils";
import Paper from "@mui/material/Paper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useEffect } from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import RemoveAlertModal from "./remove";
import AlertMessage from "constants/AlertMessage";
import ToastAlert from "constants/ToastAlert";
import { useLocation, useParams } from "react-router-dom";
import { useChartviewHook } from "pages/chartView/useChartviewHook";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const EquipmentSetting = () => {
  const { componentList } = useChartviewHook();
  const { id } = useParams();
  const { state } = useLocation();
  const { GlobalAlertList,NodeAlertList,nodeListParam,TargetAlert, nodeList ,NodeTargetAlert} = useSelector(state => state.dashboard);
  const {
    fetchAddGlobalAlert,
    fetchAddNodeAlert,
    fetchGetGlobalAlertList,
    fetchGetNodeAlertList,
    fetchDeleteGlobalAlert,
    fetchDeleteNodeAlert,
    fetchGetListProjectParams,
    fetchGettNodeListProjectParams,
    fetchSetTargetAlert,
    fetchGetTargetAlert,
    fetchNodeSetTargetAlet,
    fetchNodeGetTargetAlet
  } = useDashboardHook();
  const [selectView, setSelectView] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100000);
  const [rowsvalue, setRowsValue] = React.useState([]);
  const initialTableHeading = [
    {
      sortId: "parametervalue",
      numeric: false,
      disablePadding: true,
      label: "Parameter",
      show: true,
    },
    {
      sortId: "operation",
      numeric: true,
      disablePadding: false,
      label: "Operation",
      show: true,
    },
    {
      sortId: "value",
      numeric: true,
      disablePadding: false,
      label: "Value",
      show: true,
    },
    {
      sortId: "timestart",
      numeric: false,
      disablePadding: false,
      label: "Time Duration",
      show: true,
    },
    // {
    //   sortId: "type",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Type",
    //   show: true,
    // },
    {
      sortId: "severity",
      numeric: false,
      disablePadding: false,
      label: "Severity",
      show: true,
    },
    {
      sortId: "limit",
      numeric: false,
      disablePadding: false,
      label: "Alert Limit",
      show: true,
    },
  ];
  const [tableHeading, setTableHeading] = React.useState(initialTableHeading);
  const [parameter, setParameter] = React.useState("");
  const [energy,setEnergy] = React.useState("");
  const [parameterError, setParameterError] = React.useState(false);
  const [operation, setOperation] = React.useState("");
  const [operationError, setOperationError] = React.useState(false);
  const [alertvalue, setAlertValue] = React.useState("");
  const [alertvalueError, setAlertvalueError] = React.useState(false);
  const [timeDurationFrom, setTimeDurationFrom] = React.useState(null);
  const [timeDurationTo, setTimeDurationTo] = React.useState(null);
  const [timeDurationError, setTimeDurationError] = React.useState(false);
  const [type, setType] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [severityError, setSeverityError] = React.useState(false);
  const [alertLimit, setAlertLimit] = React.useState("");
  const [alertLimitError, setAlertLimitError] = React.useState(false);
  const [mail, setMail] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [allEmail, setAllEmail] = React.useState([]);
  const [contactNumber, setContactNumber] = React.useState("");
  const [allcontactNumber, setAllContactNumber] = React.useState([]);
  const [contactNumberError, setContactNumberError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [alertError, setAlertError] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState({
    open: false,
    id: null,
  });
  const [loading, setLoading] = React.useState(true);
  const [TargetAlertActive,setTargetAlertActive]= React.useState(true);
  const [listParam,setListParam] = React.useState([]);

  const handlesuccessModalClose = () => {
    setSuccessModal(false);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    if (orderBy === "parametervalue" && order === "desc") {
      return (a, b) => b[orderBy].localeCompare(a[orderBy]);
    } else if (orderBy === "parametervalue" && order === "asc") {
      return (a, b) => a[orderBy].localeCompare(b[orderBy]);
    }
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const tableRecords = React.useMemo(() => {
    return stableSort(rowsvalue, getComparator(order, orderBy))?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [rowsvalue, order, orderBy, page, rowsPerPage]);

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  useEffect(()=>{
    if(listParam){
      const sortedListParam = listParam.sort((a, b) => {
        const aListParam = a.name || a.title; 
        const bListParam = b.name || b.title;
    
        return aListParam.localeCompare(bListParam);
      })
      setListParam(sortedListParam)
    }
  },[listParam])

  function areArraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        const val1 = arr1[i];
        const val2 = arr2[i];

        if (typeof val1 === 'object' && typeof val2 === 'object') {
            if (!isObjectEqual(val1, val2)) {
                return false;
            }
        } else if (val1 !== val2) {
            return false;
        }
    }

    return true;
}
function isObjectEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
      return false;
  }

  for (let key of keys1) {
      const prop1 = obj1[key];
      const prop2 = obj2[key];

      if (typeof prop1 === 'object' && typeof prop2 === 'object') {
          if (!isObjectEqual(prop1, prop2)) {
              return false;
          }
      } else if (prop1 !== prop2) {
          return false;
      }
  }

  return true;
}

  const handleSelectChange = (selectedValue) => {
    const selectedParam = listParam.find((param) => param.param_id === selectedValue);
    const selectedEnergy = listParam.find((param) => param.title === selectedValue);
  
    if (selectedParam) {
      setParameter(selectedParam.param_id);
      setEnergy('');
    } else if (selectedEnergy) {
      setEnergy(selectedEnergy.title);
      setParameter('');
    }
    setParameterError(false)
  };

  const handleAlertLimitChange = (e) => {
    let inputValue = e.target.value.replace(/[^0-9]/g, '');
    setAlertLimit(inputValue);
    inputValue && setAlertLimitError(false)
  };

  const handleAlertValueChange = (e) => {

    let inputValue = e.target.value.replace(/[^0-9.]/g, '');
    inputValue = inputValue.replace(/(\.\d*\.)/g, '$1').replace(/^(\d*\.\d*).*$/g, '$1');
    setAlertValue(inputValue);
    inputValue && setAlertvalueError(false)
    };
  
  function validateGlobalAlert() {
    let isValid = true;
    if (
      !parameter&&!energy ||
      !operation ||
      !alertvalue ||
      !timeDurationFrom ||
      !timeDurationTo ||
      !severity ||
      !alertLimit
    ) {
      isValid = false;
    }
    if (!parameter&&!energy) {
      isValid = false;
    }
   if(timeDurationFrom > timeDurationTo){
    isValid = false;
   }
    return isValid;
  }
  const getTargetAlert =async ()=>{
    if(id){
      const stringNodeList = localStorage.getItem('nodeList') ? JSON.parse(localStorage.getItem('nodeList')) : [];
      const storageNodeList = (nodeList && nodeList.length > 0) ? nodeList : stringNodeList

      const payload = { nodes : storageNodeList }

      const nodeTargetAlert =await fetchNodeGetTargetAlet(payload);
      if(nodeTargetAlert){
        console.log(nodeTargetAlert)
        nodeTargetAlert[id]?.metadata?.email?setAllEmail(nodeTargetAlert[id]?.metadata.email):setAllEmail([])
        nodeTargetAlert[id]?.metadata?.mobile?setAllContactNumber(nodeTargetAlert[id]?.metadata.mobile):setEmail([])
      }
    }else{
      const targetAlert =await fetchGetTargetAlert();
      if(targetAlert){
        setAllEmail(targetAlert.email)
        setAllContactNumber(targetAlert.mobile)
      }
    }
  }
  useEffect(() => {
    getAlertList();
    // getAlertParam();
    setSelectView(false)
    getTargetAlert()
    }, [id]);

  const getAlertList = async () => {
    let payload = {
      offset: 0,
      limit: 100,
      orderby: "asc",
    };
    if(id){
      await fetchGetNodeAlertList(payload);
      setLoading(false);
    }else{
       await fetchGetGlobalAlertList(payload);
      setLoading(false);
    }
  };

  // const getAlertParam = async ()=>{
  //   let payload ={
  //     "limit": 100,
  //     "offset": 0
  //   }
  //   if(id){
  //     let nodeparams =await fetchGettNodeListProjectParams(payload)
  //     setListParam(nodeparams)
  //   }else{
  //     let listParam =await fetchGetListProjectParams(payload)
  //     setListParam(listParam)
  //   }
  // }
  const timeFormat = (time) => {
    const selectedTimeString = time;
    const selectedTime = new Date(selectedTimeString);

    const hours = selectedTime.getHours().toString().padStart(2, "0");
    const minutes = selectedTime.getMinutes().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;
    return formattedTime;
  };
  useEffect(()=>{
    nodeListParam?setListParam([...nodeListParam,...componentList]):setListParam([componentList]);
  },[nodeListParam])

  useEffect(()=>{
    if(id){
      if(NodeAlertList&&listParam){
        let nodeAlert =NodeAlertList?NodeAlertList.filter((alertId)=>alertId.nodeid===id):[]
        nodeAlert.forEach(obj => {
          const selectedNodeParam = listParam.find((param) => param.param_id === obj.paramid);
          const selectedEnergyParam = listParam.find((param) => param.value === obj.paramname);
          const newValue = selectedEnergyParam ? selectedEnergyParam.title : selectedNodeParam ? selectedNodeParam.name : '';
          obj.parametervalue = newValue;
      });
      setRowsValue(nodeAlert.reverse());

      }else{
        setRowsValue([]);
      }

    }else{
      if(GlobalAlertList&&listParam){
        let newGlobalAlertList = [...GlobalAlertList]
        newGlobalAlertList.forEach(obj => {
          const selectedNodeParam = listParam.find((param) => param.param_id === obj.paramid);
          const selectedEnergyParam = listParam.find((param) => param.value === obj.paramname);
          const newValue = selectedEnergyParam ? selectedEnergyParam.title : selectedNodeParam ? selectedNodeParam.name : '';
          obj.parametervalue = newValue;
      });
      setRowsValue(newGlobalAlertList.reverse());

      }else{
        setRowsValue([]);
      }
    }
  },[GlobalAlertList,NodeAlertList,listParam])

  useEffect(() => {
    if ( !allcontactNumber.length > 0 && !contactNumber.length > 0 && !email.length > 0 && !allEmail.length > 0) {
       setTargetAlertActive(false);
    } else {
      setTargetAlertActive(true);
    }
    if(TargetAlert && !email.length > 0 && !contactNumber.length > 0){
      let equalEmail = areArraysEqual(allEmail,TargetAlert.email)
      let equalmobile = areArraysEqual(allcontactNumber,TargetAlert.mobile)
      setTargetAlertActive(!(equalEmail&&equalmobile));
    }

    if(NodeTargetAlert && !email.length > 0 && !contactNumber.length > 0 && id){
      const NodeTargetAlertEmail =NodeTargetAlert[id]?.metadata.email?NodeTargetAlert[id]?.metadata?.email:[];
      const NodeTargetAlertMobile =NodeTargetAlert[id]?.metadata.mobile?NodeTargetAlert[id]?.metadata?.mobile:[];

      let equalEmail = areArraysEqual(allEmail,NodeTargetAlertEmail)
      let equalmobile = areArraysEqual(allcontactNumber,NodeTargetAlertMobile)
      setTargetAlertActive(!(equalEmail&&equalmobile));
    }
  }, [allcontactNumber, contactNumber, email, allEmail]);

  const handleCreateAlert = async () => {
    if (validateGlobalAlert()) {
      setOrder("asc");
      setOrderBy("");
      if(id){
        const paramValue = listParam.find((param) => param.title === energy);
        let payload = {
          paramid: parameter,
          param: paramValue?paramValue.value:"",
          operation: operation,
          nodeid:id,
          timeStart: timeFormat(timeDurationFrom),
          value: Number(alertvalue),
          timeEnd: timeFormat(timeDurationTo),
          severity: severity,
          limit: Number(alertLimit),
        };
        let addAlert = await fetchAddNodeAlert(payload);
        if (!addAlert.success) {
          AlertMessage({ msg: addAlert.error })
          return;
        } else {
          ToastAlert({ msg: "Added Alert successfully", msgType: "info" })
          getAlertList()
          setSelectView(false);
        }
      }else{
        const paramValue = listParam.find((param) => param.title === energy);
        let payload = {
          paramid: parameter,
          param: paramValue?paramValue.value:"",
          operation: operation,
          timeStart: timeFormat(timeDurationFrom),
          value: Number(alertvalue),
          timeEnd: timeFormat(timeDurationTo),
          severity: severity,
          limit: Number(alertLimit),
        };
        let addAlert = await fetchAddGlobalAlert(payload);
        if (!addAlert.success) {
          AlertMessage({ msg: addAlert.error })
          return;
        } else {
          ToastAlert({ msg: "Added Alert successfully", msgType: "info" })
          getAlertList()
          setSelectView(false);
        }
      }
      
    } else {
      (!parameter.length > 0 && !energy.length > 0)  && setParameterError(true);
      !operation.length>0 && setOperationError(true);
      !alertvalue.length >0 && setAlertvalueError(true);
      (!timeDurationFrom || !timeDurationTo) && setTimeDurationError(true)
      timeDurationFrom > timeDurationTo && setTimeDurationError(true)
      !severity.length>0 && setSeverityError(true)
      !alertLimit.length >0 &&setAlertLimitError(true)
      // alert("Select All Field");
      showAddAlertForm()
    }
  };

  function validateSetAlert() {
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!allEmail.length > 0) {
      if (!emailRegex.test(email)&&email.length > 0) {
       setEmailError("Invalid email address")
        isValid = false;
      }
    } else {
      if (email.length > 0) {
        if (!emailRegex.test(email)) {
          setEmailError("Invalid email address");
          isValid = false;
        } else {
          let sameEmil = allEmail.filter((emaildata)=>emaildata===email);
          if (email.trim() !== "" && !sameEmil.length>0) {
            allEmail.push(email);
            setEmail("");
          }else{
            setEmailError("this email already entered");
            isValid = false;
          }
        }
      }
    }

    const phoneRegex = /^[0-9]{10}$/;
    if (!allcontactNumber.length > 0) {
      if (!phoneRegex.test(contactNumber) && contactNumber.length > 0) {
           setContactNumberError("Invalid phone number")
        isValid = false;
      }
    } else {
      if (contactNumber.length > 0) {
        if (!phoneRegex.test(contactNumber)) {
          setContactNumberError("Invalid phone number");
          isValid = false;
        } else {
          let sameContactNumber = allcontactNumber.filter((mobile)=>mobile===contactNumber);
          if (contactNumber.trim() !== "" && !sameContactNumber.length>0) {
            allcontactNumber.push(contactNumber);
            setContactNumber("");
          }else{
            setContactNumberError("this contact already entered");
            isValid = false;
          }
        }
      }
    }
    
    // if (!mail && !message) {
    //   setAlertError(true);
    //   isValid = false;
    // }
    return isValid;
  }

  let handleSetAlert =async()=> {
    if (validateSetAlert()) {
      // let alertType;
      // if (mail&&message) {
      //   alertType = ["mail","message"];
      // }else if(mail){
      //   alertType = "mail";
      // }else if(message) {
      //   alertType = "message";
      // }
      // console.log(alertType,'alertType')

      if(id){
        let payload ={
            node_id: id,
            ep_id: id,
            metadata: {
                  email: allEmail.length > 0 ? allEmail : email.length>0?[email]:[],
                  mobile: allcontactNumber.length > 0 ? allcontactNumber : contactNumber.length>0?[contactNumber]:[],
            }
        }
        let setNodeTartgetAlert = await fetchNodeSetTargetAlet(payload);
        if (!setNodeTartgetAlert.success) {
          AlertMessage({ msg: setNodeTartgetAlert.error })
          return;
        } else {
          ToastAlert({ msg: "Alert added Successfully", msgType: "info" })
          setEmail("");
          setContactNumber("");
          // setSuccessModal(true);
        }
        getTargetAlert();

      }else{
        const payload =((email.length>0||allEmail.length>0)&&(contactNumber.length>0||allcontactNumber.length>0))?
        { email: allEmail.length > 0 ? allEmail : [email],
          mobile: allcontactNumber.length > 0 ? allcontactNumber :[contactNumber],
        }:(email.length>0||allEmail.length>0)?
        {
          email: allEmail.length > 0 ? allEmail : [email]
        }:(contactNumber.length>0||allcontactNumber.length>0)?
        {
          mobile: allcontactNumber.length > 0 ? allcontactNumber :[contactNumber],
        }:{}

        let setTargetAlert = await fetchSetTargetAlert(payload);
        if (!setTargetAlert.success) {
          AlertMessage({ msg: setTargetAlert.error })
          return;
        } else {
          ToastAlert({ msg: "Alert added Successfully", msgType: "info" })
          setEmail("");
          setContactNumber("");
          // setSuccessModal(true);
        }
        getTargetAlert();
      }
    }
  }
  function handleCancelAlert() {
    if(id){
      if(NodeTargetAlert){
        NodeTargetAlert[id]?.metadata.email?setAllEmail(NodeTargetAlert[id]?.metadata?.email):setAllEmail([])
        NodeTargetAlert[id]?.metadata.mobile?setAllContactNumber(NodeTargetAlert[id]?.metadata?.mobile):setEmail([])
      }
    }else{
      if(TargetAlert){
        setAllEmail(TargetAlert.email);
        setAllContactNumber(TargetAlert.mobile)
      }
    }
    setEmail("");
    setContactNumber("");
    setContactNumberError("");
    setEmailError("");
  }
  function AddData() {
    setSelectView(true);
    setParameter("");
    setOperation("");
    setAlertValue("");
    setTimeDurationFrom(null);
    setTimeDurationTo(null);
    setType("");
    setSeverity("");
    setAlertLimit("");
    setEnergy("");
    setParameterError(false);
    setOperationError(false);
    setAlertvalueError(false);
    setTimeDurationError(false);
    setSeverityError(false)
    setAlertLimitError(false)
  }

  const timePicker = {
    width: "75px",
    marginRight: "10px",
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handledelete = (id) => {
    setDeleteModal({ open: true, id: id });
  };
  const handleAlertDelete = async (ids) => {
    if(id){
      let deleteNodeAlert = await fetchDeleteNodeAlert({ alertid: ids });
      if (!deleteNodeAlert.success) {
        AlertMessage({ msg: deleteNodeAlert.error })
        return;
      } else {
        ToastAlert({ msg: "Alert Removed successfully", msgType: "info" })
        getAlertList();
        setPage(0);
      }
    }else{
      let globalAlertDelete = await fetchDeleteGlobalAlert({ alertid: ids });
      if (!globalAlertDelete.success) {
        AlertMessage({ msg: globalAlertDelete.error })
        return;
      } else {
        ToastAlert({ msg: "Alert Removed successfully", msgType: "info" })
        getAlertList();
        setPage(0);
      }
    }
  };
  const handleDeleteClose = () => {
    setDeleteModal({ open: false, id: null });
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  const tableRef = React.useRef(null);

  const showAddAlertForm = ()=>{
        // Scroll to the bottom when data changes
    if (tableRef.current) {
      const lastRow = tableRef.current.querySelector("tbody tr:last-child");
      if (lastRow) {
        lastRow.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }
  }
  useEffect(() => {
    showAddAlertForm()
  }, [selectView]);

  const handleAddEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      if (!allEmail.length > 0) {
        email.length > 0
          ? setEmailError("Invalid email address")
          : setEmailError("email is required");
      }else{
         setEmailError("Invalid email address")
      }
    } else {
      let sameEmil = allEmail.filter((emaildata)=>emaildata===email);
      if (email.trim() !== "" && !sameEmil.length>0) {
        setAllEmail((pre) => [...pre, email]);
        setEmail("");
      }else{
        setEmailError("this email already entered");
      }
    }
  };
  const handleAddMopile = () => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(contactNumber)) {
      if (!allcontactNumber.length > 0) {
        contactNumber.length > 0
          ? setContactNumberError("Invalid phone number")
          : setContactNumberError("phone number is required");
      }else{
         setContactNumberError("Invalid phone number")
      }
    } else {
      let sameContactNumber = allcontactNumber.filter((mobile)=>mobile===contactNumber);
      if (contactNumber.trim() !== "" && !sameContactNumber.length>0) {
        setAllContactNumber((pre) => [...pre, contactNumber]);
        setContactNumber("");
      }else{
        setContactNumberError("this phone number already entered");
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddEmail();
    }
  };
    const handleEmailKeyDown = (e) => {
    if (e.key === "Backspace"&&!email.length>0) {
      let newAllEmail =[...allEmail]
      newAllEmail.pop()
      setAllEmail(newAllEmail)
    }
  };
  
  const handleMobileKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddMopile();
    }
  };
    const handleMobileKeyDown = (e) => {
    if (e.key === "Backspace"&&!contactNumber.length>0) {
      let newContactNumber =[...allcontactNumber]
      newContactNumber.pop()
      setAllContactNumber(newContactNumber);
    }
  };
  
  const handleEmailChipDelete = (index) => {
    const newEmails = [...allEmail];
    newEmails.splice(index, 1);
    setAllEmail(newEmails);
  };
  const handleMopileChipDelete = (index) => {
    const newMobile = [...allcontactNumber];
    newMobile.splice(index, 1);
    setAllContactNumber(newMobile);
  };
  const handleDivClick = (field) => {
    if(field==="email"){
      const inputElement = document.getElementById("email");
      if (inputElement) {
        inputElement.focus();
      }
    }else if(field==="mobile"){
      const inputElement = document.getElementById("mobile");
      if (inputElement) {
        inputElement.focus();
      }
    }

  };
  const handleChangeContact =(event)=>{
    setContactNumber(event.target.value);
    setContactNumberError("");
    setEmailError("");
  };
  const handleChangeEmail=(event)=>{
    setEmail(event.target.value);
    setContactNumberError("");
    setEmailError("");
  };
  const handleChangeMessageType =()=>{
    setMessage(!message);
    setAlertError(false);
  };
  const handleChangeMailType =()=>{
    setMail(!mail);
    setAlertError(false);
  };
  return (
    <div className="equipment_setting_tabs-container">
      {
        id&&
          <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          sx={{
            borderRight: 1,
            borderColor: "divider",
            paddingTop: "100px",
            width: "250px",
          }}
        >
        {/* <Tab
            icon={<PersonOutlineOutlinedIcon />}
            className={value == 0 ? "prof-tab-active tabcus" : "tabcus"}
            iconPosition="start"
            label="Profile"
            {...a11yProps(0)}
          /> */}
        <Tab
            icon={<FormatListBulletedIcon />}
            className={value == 1 ? "prof-tab-active tabcus" : "tabcus"}
            iconPosition="start"
            label="Alert List"
            {...a11yProps(1)}
          />
        </Tabs>
      }

      {/* {value == 0 ? (
        <div className="equipment_setting_container">
          <div className="equipment_setting_header">
            <div>
            {state?<h3>{state.nodeName}</h3>:<></>}
              <h4>Profile</h4>
            </div>
          </div>
        </div>
       ) : (  */}
      <div className="equipment_setting_container">
        <div className="equipment_setting_header">
          <div>
            {state?<h3>{state.nodeName}</h3>:<></>}
            <h4>Alert List</h4>
          </div>
        </div>
        <TableContainer
          sx={{
            boxShadow: "unset",
            maxHeight: "calc(100vh - 290px)",
            marginTop: "25px",
          }}
          component={Paper}
        >
          <Table
            aria-label="collapsible table"
            className="eq-table custom-table border-collapse"
            style={{ width: "100%" }}
          >
            <TableHead
              className="equipment-table-header custom-table-header equipmentSetting-table-header"
              sx={{ zIndex: "1" }}
            >
              <TableRow className="custom-table-row">
                {tableHeading.map((headCell) => {
                  let SORT_ID = headCell?.sortId
                    ? headCell?.sortId
                    : headCell?.id;
                  return (
                    <TableCell
                      key={headCell.id}
                      className={`${
                        headCell.id + "-cell"
                      } first:pl-[7%] Mediumlg:first:pl-[3%]`}
                      sortDirection={orderBy === SORT_ID ? order : false}
                      style={
                        headCell.show
                          ? { background: "#f5f5f5", borderBottom: "unset" }
                          : { display: "none" }
                      }
                    >
                      <TableSortLabel
                        active={orderBy === SORT_ID}
                        direction={orderBy === SORT_ID ? order : "asc"}
                        onClick={createSortHandler(
                          headCell?.sortId ? headCell?.sortId : headCell?.id
                        )}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "700",
                            color: "#7F7F7F",
                          }}
                        >
                          {headCell.label}
                        </span>
                        {orderBy === SORT_ID ? (
                          <Box component="span" sx={visuallyHidden}></Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
                <TableCell
                  style={{
                    background: "#f5f5f5",
                    borderBottom: "unset",
                    cursor: "pointer",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7}>
                    <div className="empty_alert_data">
                      <CircularProgress />
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody ref={tableRef}>
                {rowsvalue.length > 0 ? (
                  tableRecords.map((data) => {
                    const selectedNodeParam = listParam.find((param) => param.param_id === data.paramid);
                    const selectedEnergyParam = listParam.find((param) => param.value === data.paramname);
                    return (
                      <TableRow>
                        <TableCell >
                          <div className="equipment_settings_tableCell first_tableCell">
                            {data.parametervalue}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="equipment_settings_tableCell">
                            {data.operation==='falling'?'Less Than':data.operation==='rising'?'More Than':'Offline'}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="equipment_settings_tableCell">
                            {data.value}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            className="equipment_settings_tableCell"
                            style={{ width: "200px" }}
                          >
                            <div>
                              <span>From</span>
                              {data.timestart}
                            </div>
                            <div>
                              <span>To</span>
                              {data.timeend}
                            </div>
                          </div>
                        </TableCell>
                        {/* <TableCell><div className="equipment_settings_tableCell">{data.type}</div></TableCell> */}
                        <TableCell>
                          <div className="equipment_settings_tableCell">
                            {data.severity}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="equipment_settings_tableCell-alertLimit">
                            <p>{data.limit}</p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <DeleteIcon
                            className="equipment_settings_tableCell-deleteIcon"
                            onClick={() => handledelete(data.alertid)}
                          />{" "}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <></>
                )}
                {selectView ? (
                  <TableRow>
                    <TableCell>
                      <FormControl sx={{ m: 1 }} size="small">
                        <Select
                          value={parameter||energy}
                          onChange={(e) =>  handleSelectChange(e.target.value)}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 48 * 4.5, // Adjust the maximum height of the menu
                                width: '200px', // Adjust the width of the menu
                              },
                            },
                          }}
                          sx={{
                            width: "137px",
                            height: "30px",
                            textAlign: "start",
                            borderRadius: "10px",
                          }}
                          className="equipment_setting_select"
                          error={parameterError}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {listParam.map((param)=>
                          <MenuItem key={param.param_id || param.title} value={param.param_id||param.title}>{param.name||param.title}</MenuItem>
                          )}
                        </Select>
                       <div className="equipment_setting_select-error">{parameterError? 'Parameter Is Required' : ''}</div>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl sx={{ m: 1 }} size="small">
                        <Select
                          value={operation}
                          onChange={(e) =>{setOperation(e.target.value);setOperationError(false)}}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            width: "137px",
                            height: "30px",
                            textAlign: "start",
                            borderRadius: "10px",
                          }}
                          className="equipment_setting_select"
                          error={operationError}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>

                          <MenuItem value="falling">Less Than</MenuItem>
                          <MenuItem value="rising">More Than</MenuItem>
                          <MenuItem value="offline">Offline</MenuItem>
                        </Select>
                        <div className="equipment_setting_select-error">{operationError? 'Operation Is Required' : ''}</div>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <input
                        type="text"
                        value={alertvalue}
                        onChange={(e) => handleAlertValueChange(e)}
                        className={alertvalueError?"enter_value input_error":"enter_value"}
                        placeholder="Enter Value"
                      />
                    <div className="equipment_setting_select-error">{alertvalueError? 'Value Is Required' : ''}</div>
                    </TableCell>
                    <TableCell>
                      <div className="enter_from_to">
                        <label>From</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            value={timeDurationFrom}
                            sx={timePicker}
                            error ={timeDurationError}
                            onChange={(newValue) => {
                              if (newValue.isValid()) {
                                setTimeDurationFrom(newValue);
                              }else{
                                setTimeDurationFrom(null);  
                              }
                              setTimeDurationError(false)
                            }}
                            ampm={false}
                          />
                        </LocalizationProvider>

                        <label>To</label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            value={timeDurationTo}
                            sx={timePicker}
                            minTime={timeDurationFrom}
                            error={timeDurationError}
                            onChange={(newValue) => {
                              if (newValue.isValid()) {
                                setTimeDurationTo(newValue);
                              }else{
                                setTimeDurationTo(null);  
                              }
                              setTimeDurationError(false)
                            }}
                            ampm={false}
                            readOnly={timeDurationFrom ? false : true}
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="equipment_setting_select-error">{timeDurationError? 'Invalid Time' : ''}</div>
                    </TableCell>
                    {/* <TableCell>
                      <FormControl sx={{ m: 1 }} size="small">
                        <Select
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            width: "102px",
                            height: "30px",
                            textAlign: "start",
                            borderRadius: "10px",
                          }}
                          className="equipment_setting_select"
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          <MenuItem value="Continuous">Continuous</MenuItem>
                          <MenuItem value="Instantaneous">Instantaneous</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell> */}
                    <TableCell>
                      <FormControl sx={{ m: 1 }} size="small">
                        <Select
                          value={severity}
                          onChange={(e) => {setSeverity(e.target.value);setSeverityError(false)}}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            width: "118px",
                            height: "30px",
                            textAlign: "start",
                            borderRadius: "10px",
                          }}
                          className="equipment_setting_select"
                          error={severityError}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>

                          <MenuItem value="low">Low</MenuItem>
                          <MenuItem value="medium">Medium</MenuItem>
                          <MenuItem value="high">High</MenuItem>
                        </Select>
                        <div className="equipment_setting_select-error">{severityError? 'Severity Is Required' : ''}</div>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <div class="alert_limit">
                        <input
                          type="text"
                          value={alertLimit}
                          onChange={(e) => handleAlertLimitChange(e)}
                          placeholder="00"
                          className={alertLimitError?"enter_value input_error":"enter_value"}
                        />
                      </div>
                      <div className="equipment_setting_select-error">{alertLimitError? 'Alert Limit Is Required' : ''}</div>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={()=>setSelectView(false)}>
                    <CloseIcon />
                  </IconButton>
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {rowsvalue.length > 0 && !selectView && !loading ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: 100000 }]}
            colSpan={3}
            count={rowsvalue.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            style={{ display: "block", borderBottom: "unset" }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            className="equipment-pagination"
          />
        ) : (
          <></>
        )}
        {loading ? (
          <></>
        ) : selectView ? (
          <div className="equipment_setting-add_btn">
            <button onClick={handleCreateAlert}>Add</button>
          </div>
        ) : rowsvalue.length > 0 ? (
          <div className="equipment_setting-add_btn-plus">
            <button onClick={AddData}>+</button>
          </div>
        ) : (
          <div className="equipment_setting-add_btn">
            <button onClick={() => setSelectView(!selectView)}>
              Add New Alert
            </button>
          </div>
        )}

        <div className="equipment_setting-alert_message">
          <div className="equipment_setting-alert_message-header">
            <h4>Alert Message</h4>
          </div>
          <div>
            {/* <div className="equipment_setting-alert_message-input">
              <p>Alert Type</p>
              <div className="equipment_setting-alert_message-checkBox">
                <div>
                  <input
                    type="checkbox"
                    id="mail"
                    checked={mail}
                    onClick={handleChangeMailType}
                  />
                  <label for="mail">Mail</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="message"
                    checked={message}
                    onChange={handleChangeMessageType}
                  />
                  <label for="message">Message</label>
                </div>
              </div>
            </div> */}

            <div className="equipment_setting-alert_message-input equipment_setting-alert_message-email">
              <p>Mail</p>
              <div
                className="equipment_setting-alert_message-input_text"
                onClick={()=>handleDivClick('email')}
              >
                <div className="email_box">
                  {allEmail.map((email, index) => (
                    <Chip
                      key={index}
                      label={email}
                      onDelete={() => handleEmailChipDelete(index)}
                      style={{ margin: "4px" }}
                    />
                  ))}
                  <input
                    className="email_box-input"
                    autoComplete="off"
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => handleChangeEmail(e)}
                    onKeyPress={handleKeyPress}
                    onKeyDown={handleEmailKeyDown}
                    placeholder="Enter E- Mail ID"
                  />
                </div>

                <h5>{emailError}</h5>
              </div>
            </div>

            <div className="equipment_setting-alert_message-input">
              <p>Contact Number</p>
              <div
                className="equipment_setting-alert_message-input_text"
                onClick={()=>handleDivClick('mobile')}
              >
                <div className="email_box">
                  {allcontactNumber.map((mobile, index) => (
                    <Chip
                      key={index}
                      label={mobile}
                      onDelete={() => handleMopileChipDelete(index)}
                      style={{ margin: "4px" }}
                    />
                  ))}
                  <input
                    className="email_box-input"
                    autoComplete="off"
                    type="text"
                    id="mobile"
                    value={contactNumber}
                    onChange={(e) => handleChangeContact(e)}
                    onKeyPress={handleMobileKeyPress}
                    onKeyDown={handleMobileKeyDown}
                    placeholder="Enter Phone Number"
                    />
                </div>

                <h5>{contactNumberError}</h5>
              </div>
            </div>

            <div className="equipment_setting-alert_message_buttons">
              <button
                className={`${TargetAlertActive?"set_alert_btn-selected":"equipment_setting-alert_message_buttons-btn"}`}
                onClick={handleSetAlert}
                disabled={!TargetAlertActive}
              >
                Set Alert
              </button>
              <button
                className="equipment_setting-alert_message_buttons-btn"
                onClick={handleCancelAlert}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* )}  */}

      {/* <Dialog
        open={successModal}
        onClose={handlesuccessModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="alert-dialog-title-description">
          <DialogContentText
            id="alert-dialog-description"
            className="alert-dialog-description"
          >
            Alert for <span>AW - 3456</span> added Successfully
          </DialogContentText>
        </DialogContent>
      </Dialog> */}

      {deleteModal.open && (
        <RemoveAlertModal
          open={deleteModal.open}
          id={deleteModal.id}
          alertId={deleteModal.id}
          handleDeleteClose={handleDeleteClose}
          handleAlertDelete={handleAlertDelete}
        />
      )}
    </div>
  );
};

export default EquipmentSetting;
