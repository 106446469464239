export const baseUrl = process.env.REACT_APP_API_BASE_URL; //'https://pbe.unitgrid.in/v1/ug-backend/';

export const LoginApi = 'login';
export const ResetPasswordApi = 'resetPassword';
export const UserLogoutApi = 'logout';
export const RefreshTokenApi = 'refreshToken';
export const UserList = 'getAllUser';
export const AddUserApi = 'addUser';
export const UpdateUserApi = 'update';
export const UpdatePasswordApi = 'updatepassword';
export const NodeListApi = 'node/list';
export const GetNodeDetails = 'node/details';
export const GetDeviceInstEnergy = 'getDeviceInstEnergy';
// export const GetHistoryProject = 'energy/time-aggregates';
export const GetConsumptionPattern = 'energy/time-aggregates';
export const GetNodeListParam = 'node/listParam';
export const GetProjectParam = 'listProjectParams';
export const GetDeviceInstParam = 'getDeviceInstParamData';
export const DeleteUserApi = 'deleteUser';
export const GetDeviceHistoricalEnergy = 'getDeviceHistoricalEnergy';
export const getUserProfile = 'getUserProfile';
export const GetNodeMetaDataApi = 'node/getmetadata';
export const GetRecentNodeEnergy = 'recentNodeEnergy';
export const GetSelectedNodeParams = 'dashboard/preferences/';
export const SaveSelectedNodeDatas =  'dashboard/preference';
export const GetDeviceHistoricalParamData = 'getDeviceHistoricalParamData';
export const AddEquipmentApi = 'AddEquipment';
export const EditEquipmentApi = 'node/upsertmetadata';
export const RemoveEquipmentApi = 'deleteEquipment';
export const CreateConfigApi = "createConfig"; 
export const GetConfigApi = "getConfig";
export const UpdateConfigApi = "updateConfig";
export const RemoveConfigApi = "removeConfig";
export const GetStreetLightApi ="getStreetlight";
export const AddGlobalAlert = "alerts/create";
export const GetGlobalAlertList = "alerts/list";
export const DeleteGlobalAlert = "alerts/delete";
export const AddNodeAlert = "alerts/node/create";
export const GetNodeAlertList = "alerts/node/list";
export const DeleteNodeAlert = "alerts/node/delete";
export const ListProjectParams = "listProjectParams";
export const NodeListProjectParams = "node/listParam";
export const SetTargetAlert ="alerts/settargets";
export const GetTargetAlert ="alerts/gettargets";
export const AlertEvents ="alerts/events";
export const NodeSetTargetAlet = "node/upsertmetadata";
export const NodeGetTargetAlet = "node/getmetadata";










