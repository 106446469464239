import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { CircularProgress, Paper, Popover, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useHook as useDashboardHook } from "../../pages/dashboard/useHook";
const IconButtonComponent = ({ children, ...props }) => {
  const {
    fetchGetAlertEvents
  } = useDashboardHook();
  const notificationIcon = props.notification;
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [ alertEvent ,setAletEvent]=React.useState([]);
  const [unRead ,setUnRead]=React.useState([]);
  const [loading ,setLoading] = React.useState(true)


  const handleNotificationClick = (event) => {
    getAlertEvents()
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const openNotification = Boolean(notificationAnchorEl);
  const nodificationId = openNotification ? "simple-popover" : undefined;

  const [alignment, setAlignment] = React.useState('all');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const getAlertEvents = async()=>{
    const alertEvents =await fetchGetAlertEvents({
      "offset": 0,
      "limit": 100,
      "orderby": "desc"
  })
  setAletEvent(alertEvents);
  setLoading(false);
  }
  useEffect(()=>{
    if(alertEvent){
      let unReadMessage = alertEvent.filter((data)=>data.read === false)
      setUnRead(unReadMessage)
    }
  },[alertEvent])

  function timeAgo(timestamp) {
    const currentDate = new Date();
    const inputDate = new Date(timestamp * 1000); // Assuming timestamp is in seconds

    const timeDifference = currentDate - inputDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (seconds < 60) {
        return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    } else if (minutes < 60) {
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (hours < 24) {
        return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (days < 7) {
        return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (weeks < 4) {
        return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
    } else {
        return 'more than a month ago';
    }
}


  return (
    <>
      {notificationIcon ? (
        <IconButton
          aria-describedby={nodificationId}
          variant="contained"
          onClick={handleNotificationClick}
          sx={{
            color: "#000",
            ":hover": {
              bgcolor: "white",
              color: "rgba(27, 83, 249, 1)",
            },
            ":active": {
              bgcolor: "rgba(3, 237, 165, 0.05)",
              color: "rgba(27, 83, 249, 1)",
            },
          }}
          {...props}
        >
          {children}
        </IconButton>
      ) : (
        <IconButton
          sx={{
            color: "#000",
            ":hover": {
              bgcolor: "white",
              color: "rgba(27, 83, 249, 1)",
            },
            ":active": {
              bgcolor: "rgba(3, 237, 165, 0.05)",
              color: "rgba(27, 83, 249, 1)",
            },
          }}
          {...props}
        >
          {children}
        </IconButton>
      )}

      <Popover
        id={nodificationId}
        open={openNotification}
        anchorEl={notificationAnchorEl}
        onClose={handleNotificationClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="notification_Content">
          <div className="notification_Content-header">
            <h2>Notifications</h2>
            {/* <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
              size="small"
            >
              <ToggleButton value="all" aria-label="All">
                All
              </ToggleButton>
              <ToggleButton value="unread" aria-label="Unread">
                Unread
              </ToggleButton>
            </ToggleButtonGroup> */}
          </div>
          {alignment === "all" ? (
            <>
              {/* <div className="notification_Content-sub-header">
                <h4>Today</h4>
              </div> */}
              {loading ? (
                <div className="empty_alert_data">
                  <CircularProgress />
                </div>
              ) : (
                alertEvent?.map((data) => {
                  return (
                    <div className="notification_Content_msg">
                      {data.message}
                      <p>{timeAgo(data.updated_at)}</p>
                    </div>
                  );
                })
              )}
            </>
          ) : (
            <>
              <div className="notification_Content-sub-header">
                <h4>Today</h4>
              </div>
              {unRead?.map((data) => {
                return (
                  <div className="notification_Content_msg">
                    {data.message}
                    <p>{timeAgo(data.updated_at)}</p>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </Popover>
    </>
  );
};

export default IconButtonComponent;
