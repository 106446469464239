import {
  NodeListApi,
  GetDeviceInstEnergy,
  GetHistoryProject,
  GetConsumptionPattern,
  GetNodeListParam,
  GetDeviceInstParam,
  GetDeviceHistoricalEnergy,
  GetRecentNodeEnergy,
  GetSelectedNodeParams,
  GetProjectParam,
  SaveSelectedNodeDatas,
  AddGlobalAlert,
  GetGlobalAlertList,
  DeleteGlobalAlert,
  AddNodeAlert,
  GetNodeAlertList,
  DeleteNodeAlert,
  ListProjectParams,
  NodeListProjectParams,
  SetTargetAlert,
  GetTargetAlert,
  AlertEvents,
  NodeSetTargetAlet,
  NodeGetTargetAlet,
} from "../../../routes/apiRoutes.jsx";
import { axiosGet, axiosPost } from "../commonApis";
export const baseUrl = process.env.REACT_APP_API_BASE_URL; //'https://pbe.unitgrid.in/v1/ug-backend/';

export const handleGetNodeList = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(NodeListApi, body, token);
    return response.data;
  };
};

export const handleGetDeviceInstEnergy = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(GetDeviceInstEnergy, body, token);
    return response.data;
  };
};

export const handleAddGlobalAlert = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(AddGlobalAlert, body, token);
    return response.data;
  };
};

export const handleAddNodeAlert = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(AddNodeAlert, body, token);
    return response.data;
  };
};

export const handleGetGlobalAlertList = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(GetGlobalAlertList, body, token);
    return response.data;
  };
};

export const handleGetNodeAlertList = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(GetNodeAlertList, body, token);
    return response.data;
  };
};

export const handleSetTargetAlert = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(SetTargetAlert, body, token);
    return response.data;
  };
};
export const handleGetTargetAlert = (token) => {
  return async () => {
    const response = await axiosGet(GetTargetAlert);
    return response.data;
  };
};
export const handleDeleteGlobalAlert = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(DeleteGlobalAlert, body, token);
    return response.data;
  };
};

export const handleDeleteNodeAlert = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(DeleteNodeAlert, body, token);
    return response.data;
  };
};

export const handleGetListProjectParams = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(ListProjectParams, body, token);
    return response.data;
  };
};

export const handleGetNodeListProjectParams = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(NodeListProjectParams, body, token);
    return response.data;
  };
};

export const handleGetAlertEvents = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(AlertEvents, body, token);
    return response.data;
  };
};

export const handleNodeSetTargetAlet = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(NodeSetTargetAlet, body, token);
    return response.data;
  };
};

export const handleNodeGetTargetAlet = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(NodeGetTargetAlet, body, token);
    return response.data;
  };
}

export const handleGetHistoryProjectList = (token, nodeparams, filterType, IDS) => {
  return async () => {
    // const body = JSON.stringify(payload);
    // console.log(GetHistoryProject, body, token,search ,'payload')

    // const response = await axiosPost(search?GetHistoryProject:GetHistoryProject, body, token);
    // return response.data;

    const paramIds = IDS ? IDS : ["w1", "w2", "w3"];

    const responses = [];

    // Make the API calls in parallel using Promise.all
    await Promise.all(
      paramIds.map(async (params, index) => {
        const payload = {
          paramid: params,
          from: Math.floor(new Date().setHours(0, 0, 0, 0) / 1000),
          to: Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
          config: {
            aggregation: {
              compute: "avg",
              forEachNode: false,
            },
            interval: {
              measure: "minute",
              interval: 5,
            },
            responseOptions: {
              timezone: "UTC",
            },
            filter: {
              nodes: nodeparams,
              type: filterType,
            },
          },
        };

        const response = await axiosPost(
          "energy/time-aggregates",
          payload,
          token
        );
        responses.push(response.data.data);
      })
    );

    const sumData = {};
    responses.forEach((apiData) => {
      Object.entries(apiData).forEach(([nodeId, aggregates]) => {
        aggregates.forEach(({ aggregate, timestamp }) => {
          if (!sumData[timestamp]) {
            sumData[timestamp] = { aggregate: 0, timestamp };
          }
          sumData[timestamp].aggregate += aggregate;
        });
      });
    });
    return Object.values(sumData);
  };
};


export const handleGetHistoryProjectListForEquipmentDetail = (token, nodeparams, filterType, paramIds) => {
  return async () => {
    // const body = JSON.stringify(payload);
    // console.log(GetHistoryProject, body, token,search ,'payload')

    // const response = await axiosPost(search?GetHistoryProject:GetHistoryProject, body, token);
    // return response.data; 

    const responses = [];

    // Make the API calls in parallel using Promise.all
    await Promise.all(
      paramIds.map(async (params, index) => {
        const payload = {
          paramid: params,
          from: Math.floor(new Date().setHours(0, 0, 0, 0) / 1000),
          to: Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
          config: {
            aggregation: {
              compute: "avg",
              forEachNode: false,
            },
            interval: {
              measure: "minute",
              interval: 5,
            },
            responseOptions: {
              timezone: "UTC",
            },
            filter: {
              nodes: nodeparams,
              type: filterType,
            },
          },
        };

        const response = await axiosPost(
         "nodeparam/time-aggregates",
          payload,
          token
        );

        let dynamicData =Object.values(response?.data?.data)[0];
        
        let paramMapedResponse = Object.keys(response?.data?.data).map(key => {
          let values = response?.data?.data[key];
        
          return values.map(value => ({
            ...value,
            paramId: params
          }));
        }).flat();

        responses.push(paramMapedResponse);
      })
    );

  return responses;
    }
};

 

export const handleGetConsumptionPattern = (token, payload) => {
  return async () => {
    // const body = JSON.stringify(payload);
console.log(payload,"payloadfrom abi")
    let consumptionStartingPayload = {
      paramid: "import_kwh",
      from: payload.from,
      to:payload.to,
      config: {
        aggregation: {
          compute: "deltaSum",
          forEachNode: false
        },
        interval: {
          measure: "day",
          interval: 1
        },
        responseOptions: {
          timezone: "UTC"
        },
        filter: {
          "nodes": payload.nodes,
          "type": payload.type
        }
      }
    };
    const response = await axiosPost(GetConsumptionPattern, consumptionStartingPayload, token);
    return response.data;
  };
};

export const handleGetNodeListParam = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(GetNodeListParam, body, token);
    return response.data;
  };
};

export const handleGetDeviceInstParam = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(GetDeviceInstParam, body, token);
    return response.data;
  };
};

export const handleGetDeviceHistoricalEnergy = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(GetDeviceHistoricalEnergy, body, token);
    return response.data;
  };
};

export const handleGetRecentNodeEnergy = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(GetRecentNodeEnergy, body, token);
    return response.data;
  };
};

///preferences/Daily_Consumption

export const handleGetSelectedNodeParams = (token, widget_id) => {
  return async () => {
    const response = await axiosGet(GetSelectedNodeParams + widget_id, token);
    return response.data;
  };
};

export const saveNodeParams = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(SaveSelectedNodeDatas, body, token);
    return response.data;
  };
};

export const handleGetProjectParam = (token, payload) => {
  return async () => {
    const body = JSON.stringify(payload);
    const response = await axiosPost(GetProjectParam, body, token);
    return response.data;
  };
};
