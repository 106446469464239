import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import HistoryIcon from "@mui/icons-material/History";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  Badge,
  Icon,
  TextField,
} from "@mui/material";
import LogoIcon from "../../assets/unitgrid-logo.svg";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ConstructionIcon from "@mui/icons-material/Construction";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Avatar, IconButton, ListItem } from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { Link, useLocation } from "react-router-dom";
import { IconButtonComponent } from "../../components/button";
import DialogModal from "../../components/modal/dialogModal";
import { useSelector } from "react-redux";
import jwt from "jwt-decode"
import { Routes } from "../../routes/appRoutes";
import { useHook as useLoginHook } from "../auth/login/useHook";
import { ROLE } from "../../constants/Permissions"
import { decryptData, encryptData, generateNodeName, hasPermission } from "../../constants/Helper";
import TextFieldComponent from "../../components/formFields/common/textFieldComponent";
import { SelectFieldComponent } from "../../components/formFields/common/textFieldComponent";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useNavigate } from "react-router-dom";
import ExternalLink from "../../assets/ExternalLink.png"
import Left from "../../assets/Left.png"
import UpdateLeftRotation from "../../assets/UpdateLeftRotation.png"
import download3 from "../../assets/download3.png"
import collapse from "../../assets/collapse.png"
import TimeRangeFilterModal from "../chartView/timeRangeFilterModal";
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { useDispatch } from "react-redux";
import { setExpandAction } from "../../stores/actionTypes/authActions";
import Button from "@mui/material/Button";
import { textTransform } from "@mui/system";
import CSVExportBtn from "../../components/CSVExportBtn/Index"
import { useChartviewHook } from "../chartView/useChartviewHook";
import { DrawerLayout, LoaderLayout } from "components";
import { LogoTextIcon, DeviceProfileIcon, MenuPeopleIcon, MenuToolsIcon } from "assets";
import { useEquipmentHook } from "pages/equipments/useEquipmentsHook";
import { useHook as useDashboardHook } from "pages/dashboard/useHook";
import { useAutoRefreshHook } from "data/useAutoRefreshHook";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const drawerWidth = '240px';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));




const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

const MainComponent = ({ children }) => {
  const { reloadHeader } = useLoginHook();
  const { componentList } = useChartviewHook();
  const { fetchNodeMetaData } = useEquipmentHook()
  // const { autoRefreshDashboard } = useDashboardHook()
  const { autoRefreshDashboard } = useAutoRefreshHook()

  const { nodeList, projectListChartData, dashboardReload, projectParam } = useSelector(state => state.dashboard);
  const { visibleRangeData, nodeMetaData } = useSelector(state => state.equipment);
  var url = new URL(window.location.href);
  var filter = url.searchParams.get("filter");
  const navigate = useNavigate()

  const { pathname, search } = useLocation();
  const [open, setOpen] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const { token, expandPage } = useSelector(store => store.auth);
  const { FirstName: fName, LastName: lName, Email: email, UUID: uuid, RoleId: RoleId } = token ? jwt(token) : {}
  const [equipmentList, setEquipmentList] = React.useState([]);
  const [filterData, setFilterData] = React.useState();
  const [defaultEquipment, setDefaultEquipment] = React.useState('');
  const [defaultParameter, setDefaultParameter] = React.useState('');
  const [disabledParameter, setDisabledParameter] = React.useState(false)

  const [expandAppBar, setExpandAppBar] = React.useState(false);
  const [timeRangeData, setTimeRangeData] = React.useState([
    { title: "Select Time Range", value: "0" },
    { title: "Custom", value: "custom" },
  ]);
  const [showFilterModal, setShowFilterModal] = React.useState(false)
  const dispatch = useDispatch()
  const urls = ['/users',]

  const ChartAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: expandAppBar || pathname == '/chart/full-view' ? '100%' : `calc(100% - ${drawerWidth}px)`,
      zIndex: 999,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const toggleDrawer = () => {
    setOpen(!open);
    dispatch(setExpandAction(!open))

  };

  let stringNodeList = localStorage.getItem('nodeList') ? JSON.parse(localStorage.getItem('nodeList')) : [];
  let storageNodeList = (nodeList && nodeList.length > 0) ? nodeList : stringNodeList

  // console.log('nodeList', nodeList, nodeMetaData);
  React.useEffect(() => {
    (async () => {
      // console.log('nodeList calling', nodeMetaData === null && nodeList.length > 0);
      if (nodeMetaData === null && nodeList.length > 0) {
        await fetchNodeMetaData({ nodes: storageNodeList })
      }
    })()
  }, [nodeList, nodeMetaData])

  React.useEffect(() => {
    let allNodeMetaData = nodeMetaData ? Object.entries(nodeMetaData) : [];
    let list = storageNodeList.map((v) => {
      const nodeName = (allNodeMetaData.find((e) => e[0] === v)?.length > 0 && allNodeMetaData.find((e) => e[0] === v)[1]?.metadata?.node_name) ? allNodeMetaData.find((e) => e[0] === v)[1]?.metadata?.node_name : generateNodeName(v)
      return { title: nodeName, value: v }
    });
    list.sort(function (a, b) {
      if (a.title < b.title) { return -1; }
      if (a.title > b.title) { return 1; }
      return 0;
    })
    setEquipmentList([{ title: "All", value: projectParam?.powerId }, ...list])
  }, [nodeList, nodeMetaData])

  React.useEffect(() => {
    if (filter && pathname == '/chart/view' || pathname == '/chart/full-view') {

      let fltr = decryptData(filter);
      let filterObj = {};
      if (fltr && fltr !== null && fltr !== undefined) {
        filterObj = JSON.parse(fltr);
        // console.log('filterObj', filterObj)
        if (filterObj.node == 'all') {

          setDisabledParameter(true)
        }

        setFilterData(filterObj)
        setDefaultParameter(filterObj.parameter)
        console.log(filterObj,"filterObjfilterObj")
      if(filterObj?.paramid){
        setDefaultEquipment(filterObj?.paramid[0])

      }
      }
    }

  }, [filter, equipmentList])


  // const reloadPageF = () => {
  //   let data = {
  //     reload: !dashboardReload,
  //     page: pathname.split('/')[1]
  //   }
  //   reloadHeader(data)
  // }
   
  const handleAutoRefresh = () => {
    let data = {
      dashboardReload: !dashboardReload,
      time: new Date()
    }
    autoRefreshDashboard(data)
    }

  const sidebarMenu = [
    {
      path: Routes.dashboard,
      icon: <DashboardOutlinedIcon sx={{ color: "#7c7c7c" }} />,
      module: 'DASHBOARD',
      label: 'Dashboard'
    },
    {
      path: Routes.equipments,
      isImg: true,
      icon: MenuToolsIcon, //<ConstructionIcon />,
      module: 'EQUIPMENTS',
      label: 'Equipments'
    },
    {
      path: Routes.people,
      isImg: true,
      icon: MenuPeopleIcon, //<GroupsOutlinedIcon />,
      module: 'USERS',
      label: 'People'
    },
    {
      path: Routes.deviceProfile,
      isImg: true,
      icon: DeviceProfileIcon,
      module: 'DEVICES',
      label: 'Device Profile'
    },
    {
      path: Routes.equipmentSettings,
      icon: <WarningAmberIcon sx={{ color: "#7c7c7c" }} />,
      module: 'Alert',
      label: 'Alert'
    }
  ]

  const onChangeEquipment = (e) => {
    // console.log(e.target.value)

    setDefaultEquipment(e.target.value)
    if (filter) {
      let fltr = decryptData(filter);
      let filterObj = {};
      if (fltr && fltr !== null && fltr !== undefined) {
        filterObj = JSON.parse(fltr);
        filterObj.paramid = [e.target.value]

        if (projectParam?.powerId === e.target.value) {
          filterObj.node = 'all'
          setDefaultParameter('importkwh')
          // setDisabledParameter(true)
        } else {
          setDisabledParameter(false)
          setDefaultParameter('importkwh')
          filterObj.node = e.target.value
        }

        // console.log(filterObj)
        let encodedGraphData = encryptData(JSON.stringify(filterObj))
        navigate(pathname + '?filter=' + encodedGraphData)
      }
    }

  }

  const closeModal = () => {
    setShowFilterModal(false)
  }

  const openModal = () => {
    setShowFilterModal(true)

  }

  const onChangeTimeRange = (e) => {
    if (e.target.value == 'custom') {
      openModal()
    }

  }

  const onChangePerameter = (e) => {

    setDefaultParameter(e.target.value)

    if (filter) {
      let fltr = decryptData(filter);
      let filterObj = {};
      if (fltr && fltr !== null && fltr !== undefined) {
        filterObj = JSON.parse(fltr);
        filterObj.parameter = e.target.value
        filterObj.title_parameter = e.target.title

        // console.log(filterObj)

        let encodedGraphData = encryptData(JSON.stringify(filterObj))
        navigate(pathname + '?filter=' + encodedGraphData)
      }
    }

  }

  const csvHeader = [
    { label: "Time", key: "rafc" },
    // { label: "Perameter", key: "parameter" },
    { label: "Value", key: "expVal" },
    // { label: "Name", key: "node_name" },
    // { label: "Node Id", key: "node_id" },
  ]

  React.useEffect(() => {
    if (pathname != '/chart/view' || pathname != '/chart/full-view') {
      closeModal();
    }
  }, [pathname])

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex", zIndex: '9 !important' }} >
          <CssBaseline />
          {
            <>
              <ChartAppBar
                position="absolute"
                open={open}
                sx={{
                  bgcolor: "#fff",
                  boxShadow: "0px 0px 5px rgba(176, 176, 176, 0.2)",
                }}
              >
                {
                  pathname !== '/chart/full-view' &&
                  <Toolbar
                    sx={{
                      pr: "24px",
                      display: "flex",
                      justifyContent: "space-between",
                      py: 2,
                      pl: "0 !important",
                      height: '75px',
                      minHeight: '75px'
                    }}
                    className={expandAppBar ? 'expandAppbar AppBar' : 'AppBar'}
                  >
                    <Box>
                      <Icon
                        sx={{
                          height: "29px",
                          width: "64px",
                          ml: 1,
                          ...(open && { display: "none" }),
                        }}
                      >
                        <img src={LogoIcon} alt="Logo Icon" />
                      </Icon>
                    </Box>
                    <Box className="flex w-full justify-end">
                      {
                        !urls.includes(pathname) &&
                        <Box className="w-[51%] Mediumlg:w-[40%] mr-[9%]">
                          <SearchOutlinedIcon
                            sx={{
                              color: '#444444',
                              fontSize: '22px'
                            }}
                            className='absolute mt-[8px] ml-[20px]' />
                          <TextField
                            fullWidth
                            id="fullWidth"
                            hiddenLabel
                            placeholder="Search"
                            sx={{
                              bgcolor: "transparent",
                              border: 0,
                              "& input": {
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                borderRadius: "14.5px",
                                height: "1px",
                                borderRadius: "12px",
                                height: "40px",
                                py: 0,
                                paddingLeft: 6.5
                              },
                              "& fieldset": { border: "none" },
                              ":hover": {
                                "& input": { bgcolor: "rgba(217, 217, 217, 0.3)" },
                              },
                              ":focus": {
                                "& input": { bgcolor: "rgba(217, 217, 217, 0.3)" },
                              }
                            }}
                          />
                        </Box>
                      }
                      <Box>

                        {/* <IconButton><LoaderLayout /></IconButton> */}
                        <IconButtonComponent className="notification-button" notification={true}>
                          <NotificationsNoneIcon />
                        </IconButtonComponent>
                        <IconButtonComponent className="auto-refresh-button">
                          {dashboardReload === true ? (
                            <Badge variant="dot" badgeContent=" ">
                              <HistoryIcon onClick={() => handleAutoRefresh()} />
                            </Badge>
                          ) : (
                            <HistoryIcon onClick={() => handleAutoRefresh()} />
                          )}
                        </IconButtonComponent>
                        <IconButtonComponent title="Logout" onClick={() => setModalOpen(true)}>
                          <PowerSettingsNewIcon />
                        </IconButtonComponent>
                      </Box>
                    </Box>
                    <DialogModal
                      title="Logout"
                      body="Are you sure you want to leave from Unitgrid?"
                      modalOpen={modalOpen}
                      setModalOpen={setModalOpen}
                    />
                  </Toolbar>
                }

                {
                  (pathname == '/chart/view' || pathname == '/chart/full-view') &&

                  <Toolbar
                    sx={{
                      pr: "24px",
                      display: "flex",
                      justifyContent: "space-between",
                      py: 2,
                      pl: "0 !important",
                      // display: "flex",
                      // justifyContent: "space-between"
                    }}
                    className={open ? 'ChartAppBar' : 'ChartAppBar ChartAppBar-active'}
                  >
                    {
                      pathname == '/chart/full-view' &&
                      <Box>
                        <img src={LogoIcon} alt="Logo Icon" />
                      </Box>
                    }

                    <Box style={{ display: "flex", justifyContent: "center", marginLeft: "16rem" }} >
                      <SelectFieldComponent
                        id="equipment"
                        label='Equipment Name'
                        name="equipment"
                        menulist={equipmentList}
                        value={defaultEquipment}
                        onChange={onChangeEquipment}
                        // placeholder="Search"
                        autoFocus={false}
                        classes="ml-5 flex items-center"
                        selectClass='w-60'
                        isRequired={false}
                      />
                      <SelectFieldComponent
                        id="component"
                        name="component"
                        menulist={componentList}
                        value={defaultParameter}
                        disabled={disabledParameter}
                        onChange={onChangePerameter}
                        placeholder="Search"
                        autoFocus={false}
                        classes="ml-5"
                        selectClass='w-60'
                        isRequired={false}
                      />

                      {/* <SelectFieldComponent
                        id="timeRange"
                        name="timeRange"
                        menulist={timeRangeData}
                        value='0'
                        placeholder="Select Time Range"
                        autoFocus={false}
                        onChange={onChangeTimeRange}
                        classes="ml-5"
                        selectClass='w-60'
                        isRequired={false}
                      /> */}

                      <Button
                        type="submit"
                        variant=""
                        onClick={() => openModal()}
                        style={{
                          width: '289px',
                          marginLeft: "50px",
                          height: '38px',
                          border: '1px solid',
                          borderRadius: '14.5px',
                          color: 'rgba(0, 0, 0, 0.87)',
                          textTransform: "capitalize"
                        }}
                      >

                        {/* <img src={SliderSvg} style={{ marginRight: "10px" }}></img> */}
                        Select Time Range
                      </Button>

                    </Box>

                    <Box>
                      {
                        pathname == '/chart/view' &&

                        <IconButtonComponent>
                          <img src={Left} className='chart-bar-icon' onClick={() => navigate(-1)} />
                        </IconButtonComponent>
                      }


                      {
                        pathname == '/chart/full-view' &&

                        <IconButtonComponent>
                          <img src={UpdateLeftRotation} className='chart-bar-icon' />
                        </IconButtonComponent>

                      }

                      <CSVExportBtn
                        headers={csvHeader}
                        csvData={projectListChartData ? projectListChartData : []}
                        label={
                          <IconButtonComponent>
                            <img src={download3} className='chart-bar-icon' />
                          </IconButtonComponent>
                        }
                        prifix='__total_power'
                      />

                      {
                        pathname == '/chart/view' &&
                        <IconButtonComponent>
                          <img src={expandAppBar ? collapse : ExternalLink} className='chart-bar-icon' onClick={() => window.open('/chart/full-view' + search)} />
                        </IconButtonComponent>
                      }

                    </Box>

                  </Toolbar>
                }
              </ChartAppBar>
            </>
          }
          {
            pathname !== '/chart/full-view' &&
            <Drawer
              variant="permanent"
              open={open}
              sx={{
                ".MuiDrawer-paperAnchorLeft": {
                  borderRight: 0,
                  boxShadow: "0px 5px 10px #E7E7E7",
                  borderRadius: '15px',
                },
              }}
              className={`${expandAppBar ? 'expandSidebar' : ''} ${open ? 'sideBar' : 'sideBarClosed'}`}
              style={{ zIndex: 999, height: '100vh' }}
            >
              <Box
                sx={{
                  ":hover": {
                    background:
                      "linear-gradient(279.04deg, rgba(3, 237, 165, 0.05) -8.15%, rgba(27, 86, 244, 0.05) 107.52%);",
                  },
                  borderRadius: 2.5,
                }}
              >
                <Toolbar
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    px: [1],
                    py: 3,
                  }}
                >
                  <Icon
                    sx={{
                      height: "29px",
                      width: "174px",
                    }}
                  >
                    <img src={open ? LogoTextIcon : LogoIcon} alt="Logo With Text" />
                  </Icon>
                </Toolbar>
              </Box>
              <Divider sx={{ width: "75%", mx: "auto" }} />
              <Box
                display={"flex"}
                flexDirection="column"
                height={"100%"}
                justifyContent={"space-between"}
              >
                <Box>
                  <List
                    component="nav"
                    sx={{
                      px: open && 4,
                      py: 4,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: !open && "center",
                    }}
                  >
                    {
                      sidebarMenu.map((menu, key) => {
                        return (
                          hasPermission(ROLE[RoleId], menu.module) &&
                          <ListItemButton
                            selected={pathname === menu.path}
                            key={key}
                            sx={{
                              borderRadius: '10px',
                              px: open ? 2 : 0,
                              paddingLeft: open ? '1.5vw' : 0,
                              ":hover": {
                                bgcolor: "white",
                                color: "#444444",
                              },
                              "&.Mui-selected": {
                                bgcolor: "rgba(3, 237, 165, 0.05)",
                                color: "#1B53F9",
                              },
                            }}
                          >
                            <Link to={menu.path} className="flex text-inherit no-underline justify-center items-center">
                              <ListItemIcon
                                sx={{
                                  display: "flex",
                                  justifyContent: !open && "center",
                                  color: "inherit",
                                  minWidth: '37px'
                                }}
                              >
                                {menu?.isImg ? <img src={menu.icon} alt={'icon'} /> : menu.icon}

                              </ListItemIcon>
                              {open && <ListItemText primary={menu.label} />}
                            </Link>
                          </ListItemButton>

                        )
                      })
                    }



                  </List>
                  <Divider sx={{ width: "75%", mx: "auto" }} />
                </Box>
                <List component="nav" sx={{ pb: 0, px: open && 4 }}>
                  <ListItem
                    sx={{
                      flexDirection: "row-reverse",
                      pr: open ? 0 : 2,
                      justifyContent: open ? 'end' : 'center'
                    }}
                  >
                    <IconButton
                      onClick={toggleDrawer}
                      sx={{
                        bgcolor: "rgba(230, 230, 230, 0.6)",
                        ":hover": {
                          color: "rgba(27, 83, 249, 1)",
                          bgcolor: "rgba(3, 237, 165, 0.05)",
                        },
                      }}
                    >
                      {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                  </ListItem>
                  <Divider sx={{ width: !open ? "75%" : "100%", mx: "auto" }} />
                  <Box my={2}>
                    <Box
                      display="flex"
                      flexDirection={open ? "row" : "column"}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ListItem className={`${!open ? "justify-center" : ""}`}>
                        <Avatar sx={{ bgcolor: deepPurple[500] }}>{`${fName.charAt(0)}${lName.charAt(0)}`}</Avatar>
                      </ListItem>

                      <Link to={`${Routes.userDetail}/${uuid}`}>
                        <IconButton>
                          <CreateOutlinedIcon
                            sx={{
                              color: '#000'
                            }} />
                        </IconButton>
                      </Link>
                    </Box>
                    {open && (
                      <Box ml={2}>
                        <Typography>{`${fName} ${lName}`}</Typography>
                        <Typography style={{ fontSize: "small" }}>{email}</Typography>
                      </Box>
                    )}
                  </Box>
                </List>
              </Box>
            </Drawer>
          }
          {children}
        </Box>
      </ThemeProvider>

      <TimeRangeFilterModal
        showFilterModal={showFilterModal}
        closeModal={closeModal}
      />
    </>
  );
};

export default MainComponent;
