import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import moment from "moment";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { FormControl, Menu, MenuItem, OutlinedInput, Select, Tooltip, } from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import IconButton from "@mui/material/IconButton";
import BatteryCharging20Icon from "@mui/icons-material/BatteryCharging20";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import { BarChart, ResponsiveContainer, Bar, XAxis, YAxis, Tooltip as ReChartToolTip, Label, CartesianGrid, Area, AreaChart, Cell, } from "recharts";
import { apiResData } from "../../data";
import { useHook as useDashboardHook } from "../dashboard/useHook";
import ToastMessage from "../../components/toastMessage/toastMessage";
import { useDispatch, useSelector } from "react-redux";
import { setSaveIntervals, } from "../../stores/actionTypes/equipmentActions";
import { useNavigate, useParams ,useLocation} from "react-router-dom";
import { encryptData, generateNodeName, checkEquipmetIsActive, getBarColor, } from "../../constants/Helper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import CSVExportBtn from "../../components/CSVExportBtn/Index";
import { SLUG_FREQUENCY_BARCHART, SLUG_POWER_FACTOR_BARCHART, SLUG_THD_BARCHART, SLUG_VOLTAGE_BARCHART, } from "constants/variables";
import ToastAlert from "constants/ToastAlert";
import { useChartviewHook } from "../chartView/useChartviewHook";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


const DefaultMinMaxValues = {
  maxValuecurrent_Avg: 0,
  minValuecurrent_Avg: 0,
  maxValuecurrent_phase_1: 0,
  minValuecurrent_phase_1: 0,
  maxValuecurrent_phase_2: 0,
  minValuecurrent_phase_2: 0,
  maxValuecurrent_phase_3: 0,
  minValuecurrent_phase_3: 0,
  maxValuepf_Avg: 0,
  minValuepf_Avg: 0,
  maxValuepf_phase_1: 0,
  minValuepf_phase_1: 0,
  maxValuepf_phase_2: 0,
  minValuepf_phase_2: 0,
  maxValuepf_phase_3: 0,
  minValuepf_phase_3: 0,
  maxValuevoltage_Avg: 0,
  minValuevoltage_Avg: 0,
  maxValuevoltage_phase_1: 0,
  minValuevoltage_phase_1: 0,
  maxValuevoltage_phase_2: 0,
  minValuevoltage_phase_2: 0,
  maxValuevoltage_phase_3: 0,
  minValuevoltage_phase_3: 0,
  maxValuefrequency_array: 0,
  minValuefrequency_array: 0,
};

const EquipmentDetail = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const csvHeader = [
    // { label: "Time", key: "rafc" },
    // { label: "Value", key: "expVal" },
    { label: "Time", key: "csv_rafc" },
    { label: "Value", key: "csv_expVal" },
    // { label: "Name", key: "node_name" },
    // { label: "Node Id", key: "node_id" },
  ];
  const location = useLocation()
  const statevalue=location.state;
  console.log(statevalue,"statevalue")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deviceTHD, instEnergy, historyProjectList, nodeListParam, deviceInstParam, projectParam, consumptionChartSelectedDate, consumptionChartSelectedMonth } = useSelector((state) => state.dashboard);
  const [reloadGraph, setReloadGraph] = React.useState(false);
  const { filters, nodeMetaData } = useSelector((state) => state.equipment);
  const { id } = useParams();
  const [firstDayMonth, setFirstDayMonth] = React.useState(
    Math.floor(
      new Date(
        Date.UTC(
          new Date().getFullYear(),
          new Date().getMonth(),
          1,
          0,
          0,
          0,
          0
        )
      ) / 1000
    )
  );
  const [lastDayMonth, setLastDayMonth] = React.useState(
    Math.floor(
      new Date(
        Date.UTC(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0,
          23,
          59,
          59,
          999
        )
      ) / 1000
    ));

  const [currentYear, setCurrentYear] = React.useState(
    new Date().getFullYear()
  );
  const [currentMonth, setCurrentMonth] = React.useState(new Date().getMonth());
  const [currentMonthVal, setCurrentMonthVal] = React.useState(
    new Date().getMonth() + 1
  );
  const [consumptionPatternChartData, setConsumptionPatternChartData] =
    React.useState([]);
  const [combinedHistoryParamsChartData, setCombinedHistoryParamsChartData] = React.useState([]);
  const [filterParamsValue, setFilterParamsValue] = React.useState([]);




  const [headersData, setHeadersData] = React.useState({
    node_id: "",
    node_name: "",
    country: "India",
    description: "",
    remarks: "",
  });

  const [consumptionPatternPayload, setConsumptionPatternPayload] =
    React.useState({
      paramid: [projectParam?.consumptionPatternId],
      from: firstDayMonth,
      to: lastDayMonth,
      limit: 110000,
      order: "asc",
    });

  const {
    fetchDeviceHistoricalEnergy,
    fetchrecentNodeEnergy,
    fetchHistoryProjectListForEquipmentDetail,
  } = useDashboardHook();
  const [projectListPayload, setProjectListPayload] = React.useState({
    nodes: [id],
    from: Math.floor(new Date().setHours(0, 0, 0, 0) / 1000),
    to: Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
    limit: 110000,
    order: "asc",
  });

  const [nodeDetails, setNodeDetails] = React.useState({});
  const [tempNodeDetails, setTempNodeDetails] = React.useState({
    exportkvarh: 0,
    exportkwh: 0,
    frequency: 0,
    i1: 0,
    i2: 0,
    i3: 0,
    importkvarh: 0,
    importkwh: 0,
    pf1: 0,
    pf2: 0,
    pf3: 0,
    timestamp: 0,
    v1: 0,
    v12: 0,
    v2: 0,
    v23: 0,
    v3: 0,
    v31: 0,
    w1: 0,
    w2: 0,
    w3: 0,
  });
  const [nodeTHDDetails, setNodeTHDDetails] = React.useState({});
  const [hPChartTime, setHPChartTime] = React.useState();
  const [projectChartData, setProjectChartData] = React.useState({});
  const [projectListChartData, setProjectListChartData] = React.useState([]);
  const [projectListSecoundChartData, setProjectListSecoundChartData] =
    React.useState([]);
  const [paramsChartData, setParamsChartData] = React.useState([]);
  const { fetchDeviceHistoricalParamData } = useChartviewHook();
  const [min_Max_Value, setMin_Max_Value] = React.useState(DefaultMinMaxValues);
  const { fetchConsumptionPattern } = useDashboardHook();
  const [barChartData, setBarChartData] = React.useState([]);
  const [cpTotalChartData, setCpTotalChartData] = React.useState();
  const [local_consumptionPattern, setlocal_consumptionPattern] = React.useState([]);
  const[equipmentType,setEquipmentTtpe]=React.useState("street_light");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavlgateAlert = () => {
    let nodeName =headersData.node_name;
    navigate(`/alert/${id}` ,{ state: {nodeName} })
    setAnchorEl(null);
  }
  const open = Boolean(anchorEl);

console.log(equipmentType,"equipmentTypeequipmentType")
  useEffect(() => {
    console.log("test");
    (async () => {
      if (id) {
        // let allNodeMetaData = [];
        let allNodeMetaData = nodeMetaData ? Object.entries(nodeMetaData) : [];
       
       
       let node_name =
          statevalue?.node?statevalue?.node?.metadata?.node_name:
          allNodeMetaData.find((e) => e[0] === id)?.length > 0 &&
            allNodeMetaData.find((e) => e[0] === id)[1]?.metadata?.node_name
            ? allNodeMetaData.find((e) => e[0] === id)[1]?.metadata?.node_name
            : generateNodeName(id);
        let description =
          allNodeMetaData.find((e) => e[0] === id)?.length > 0
            ? allNodeMetaData.find((e) => e[0] === id)[1]?.metadata
              ?.node_desc || "-"
            : "-";
        let address =
          allNodeMetaData.find((e) => e[0] === id)?.length > 0
            ? allNodeMetaData.find((e) => e[0] === id)[1]?.metadata?.address ||
            "-"
            : "-";
        let remarks =
          allNodeMetaData.find((e) => e[0] === id)?.length > 0
            ? allNodeMetaData.find((e) => e[0] === id)[1]?.metadata?.remarks ||
            "-"
            : "-";
        setHeadersData((oldState) => ({
          ...oldState,
          node_id: id,
          node_name: node_name,
          country: address,
          description: description,
          remarks: remarks,
        }));
        if(statevalue.status){
        let daysArray = [];
        let lastddy = new Date(currentYear, currentMonthVal, 0).getDate();
        for (let i = 1; i < lastddy + 1; i++) {
          daysArray.push(i);
        }
        let consumptionTempArray = [];
        let consumptionTempArraykeys = [];
        await Promise.all(
          daysArray.map(async (val, k) => {
            let firstdd = Math.floor(
              new Date(currentYear, currentMonth, val).setHours(0, 0, 0, 0) /
              1000
            );
            let day = new Date().getUTCDate();
            let lastdd = "";
            if (val === day) {
              lastdd = Math.floor(new Date().getTime() / 1000);
            } else {
              lastdd = Math.floor(
                new Date(currentYear, currentMonth, val).setHours(
                  23,
                  59,
                  59,
                  999
                ) / 1000
              );
            }
            // let firstNodeResponce = await fetchrecentNodeEnergy({
            //   nodes: [id],
            //   time: firstdd,
            // });
            // let secondNodeResponce = await fetchrecentNodeEnergy({
            //   nodes: [id],
            //   time: lastdd,
            // });
            let firstNodeResponce =[];
            let secondNodeResponce =[];
            let netKWH = 0;
            if (Object.keys(secondNodeResponce).length > 0) {
              let netImportKWH = Number(
                Number(secondNodeResponce[id]?.importkwh) -
                Number(firstNodeResponce[id]?.importkwh)
              );
              let netExportKWH = Number(
                Number(secondNodeResponce[id]?.exportkwh) -
                Number(firstNodeResponce[id]?.exportkwh)
              );
              netKWH = Number(
                Number(netImportKWH) - Number(netExportKWH)
              ).toFixed(2);
            }
            let tempObj = {};
            tempObj.name = val;
            tempObj.pv = netKWH;
            tempObj.date = firstdd;
            consumptionTempArray.push(tempObj);
            consumptionTempArraykeys.push(Number(val));
          })
        );

        let curruntMonthCount = getAllDaysInMonth(currentYear, currentMonth);
        // console.log('curruntMonthCount', curruntMonthCount);
        var tempdatearr = [];
        for (var i = 1; i < curruntMonthCount.length + 1; i++) {
          let tempObj = {};
          if (!consumptionTempArraykeys.includes(i)) {
            tempObj.name = i;
            tempObj.date = curruntMonthCount[i];
            tempObj.pv = 0;
            tempdatearr.push(tempObj);
          }
        }

        const mainChartArray = [...consumptionTempArray, ...tempdatearr];

        mainChartArray.sort(function (a, b) {
          return a.name - b.name;
        });
        let ConsumptionPatternChartDataArray = [];
        let nodeName = id !== null ? generateNodeName(id) : "";

        barChartData.map((val, k) => {
          var customDate = new Date(currentYear, currentMonth, val?.name);
          let tempObj = {};
          tempObj.name = Number(val?.name);
          tempObj.pv = isNaN(Number(val?.pv)) ? 0 : Number(val?.pv);
          tempObj.rafc = moment(customDate).format("yyyy-MM-DD'T'HH:mm:ss'Z'");
          tempObj.node_name =allNodeMetaData.find((e) => e[0] === id)[1].length > 0? allNodeMetaData.find((e) => e[0] === id)[1]?.metadata?.equiment_id: nodeName;
          tempObj.node_id = id;
          tempObj.expVal = isNaN(Number(val?.pv)) ? 0 : Number(val?.pv);
          tempObj.csv_rafc = moment(customDate).format("yyyy-MM-DD'T'HH:mm:ss'Z'");
          tempObj.csv_expVal = val?.pv == "NaN" ? 0 : val?.pv;

          ConsumptionPatternChartDataArray.push(tempObj);
        });
        setConsumptionPatternChartData(ConsumptionPatternChartDataArray);
      } }
    })();
  }, [id, currentYear, currentMonth, barChartData]);

  useEffect(() => {
    console.log("test");
    console.log(instEnergy, 'instEnergy');
    if (id) {
      let temp = instEnergy;
      if (temp[id]) {
        setNodeDetails(temp[id]);
      } else {
        setNodeDetails(tempNodeDetails);
      }
    } else {
      setNodeDetails(tempNodeDetails);
    }
  }, [instEnergy, id]);

  // console.log('nodeDetails:', nodeDetails)

  // grapqh min/max values
  const setGraphMinMaxValues = (deviceHistoricalEnergyList) => {
    let current_phase_1 = [];
    let current_phase_2 = [];
    let current_phase_3 = [];
    let pf_phase_1 = [];
    let pf_phase_2 = [];
    let pf_phase_3 = [];
    let voltage_phase_1 = [];
    let voltage_phase_2 = [];
    let voltage_phase_3 = [];
    let frequency_array = [];

    deviceHistoricalEnergyList.map((val, k) => {
      current_phase_1.push(val.i1);
      current_phase_2.push(val.i2);
      current_phase_3.push(val.i3);
      pf_phase_1.push(val.pf1);
      pf_phase_2.push(val.pf2);
      pf_phase_3.push(val.pf3);
      frequency_array.push(val.frequency);
      voltage_phase_1.push(val.v1);
      voltage_phase_2.push(val.v2);
      voltage_phase_3.push(val.v3);
    });

    let maxValuecurrent_phase_1 = current_phase_1.max();
    let minValuecurrent_phase_1 = current_phase_1.min();

    let maxValuecurrent_phase_2 = current_phase_2.max();
    let minValuecurrent_phase_2 = current_phase_2.min();

    let maxValuecurrent_phase_3 = current_phase_3.max();
    let minValuecurrent_phase_3 = current_phase_3.min();

    let maxValuecurrent_Avg = Number(
      Number(
        Number(maxValuecurrent_phase_1) +
        Number(maxValuecurrent_phase_2) +
        Number(maxValuecurrent_phase_3)
      ) / 3
    );
    let minValuecurrent_Avg = Number(
      Number(
        Number(minValuecurrent_phase_1) +
        Number(minValuecurrent_phase_2) +
        Number(minValuecurrent_phase_3)
      ) / 3
    );

    let maxValuepf_phase_1 = pf_phase_1.max();
    let minValuepf_phase_1 = pf_phase_1.min();

    let maxValuepf_phase_2 = pf_phase_2.max();
    let minValuepf_phase_2 = pf_phase_2.min();

    let maxValuepf_phase_3 = pf_phase_3.max();
    let minValuepf_phase_3 = pf_phase_3.min();

    let maxValuepf_Avg = Number(
      Number(
        Number(maxValuepf_phase_1) +
        Number(maxValuepf_phase_2) +
        Number(maxValuepf_phase_3)
      ) / 3
    );
    let minValuepf_Avg = Number(
      Number(
        Number(minValuepf_phase_1) +
        Number(minValuepf_phase_2) +
        Number(minValuepf_phase_3)
      ) / 3
    );

    let maxValuefrequency_array = frequency_array.max();
    let minValuefrequency_array = frequency_array.min();

    let maxValuevoltage_phase_1 = voltage_phase_1.max();
    let minValuevoltage_phase_1 = voltage_phase_1.min();

    let maxValuevoltage_phase_2 = voltage_phase_2.max();
    let minValuevoltage_phase_2 = voltage_phase_2.min();

    let maxValuevoltage_phase_3 = voltage_phase_3.max();
    let minValuevoltage_phase_3 = voltage_phase_3.min();

    let maxValuevoltage_Avg = Number(
      Number(
        Number(maxValuevoltage_phase_1) +
        Number(maxValuevoltage_phase_2) +
        Number(maxValuevoltage_phase_3)
      ) / 3
    );
    let minValuevoltage_Avg = Number(
      Number(
        Number(minValuevoltage_phase_1) +
        Number(minValuevoltage_phase_2) +
        Number(minValuevoltage_phase_3)
      ) / 3
    );

    let Min_Max_Obj = {};
    Min_Max_Obj.maxValuecurrent_Avg = maxValuecurrent_Avg;
    Min_Max_Obj.minValuecurrent_Avg = minValuecurrent_Avg;
    Min_Max_Obj.maxValuecurrent_phase_1 = maxValuecurrent_phase_1;
    Min_Max_Obj.minValuecurrent_phase_1 = minValuecurrent_phase_1;
    Min_Max_Obj.maxValuecurrent_phase_2 = maxValuecurrent_phase_2;
    Min_Max_Obj.minValuecurrent_phase_2 = minValuecurrent_phase_2;
    Min_Max_Obj.maxValuecurrent_phase_3 = maxValuecurrent_phase_3;
    Min_Max_Obj.minValuecurrent_phase_3 = minValuecurrent_phase_3;

    Min_Max_Obj.maxValuepf_Avg = maxValuepf_Avg;
    Min_Max_Obj.minValuepf_Avg = minValuepf_Avg;
    Min_Max_Obj.maxValuepf_phase_1 = maxValuepf_phase_1;
    Min_Max_Obj.minValuepf_phase_1 = minValuepf_phase_1;
    Min_Max_Obj.maxValuepf_phase_2 = maxValuepf_phase_2;
    Min_Max_Obj.minValuepf_phase_2 = minValuepf_phase_2;
    Min_Max_Obj.maxValuepf_phase_3 = maxValuepf_phase_3;
    Min_Max_Obj.minValuepf_phase_3 = minValuepf_phase_3;

    Min_Max_Obj.maxValuevoltage_Avg = maxValuevoltage_Avg;
    Min_Max_Obj.minValuevoltage_Avg = minValuevoltage_Avg;
    Min_Max_Obj.maxValuevoltage_phase_1 = maxValuevoltage_phase_1;
    Min_Max_Obj.minValuevoltage_phase_1 = minValuevoltage_phase_1;
    Min_Max_Obj.maxValuevoltage_phase_2 = maxValuevoltage_phase_2;
    Min_Max_Obj.minValuevoltage_phase_2 = minValuevoltage_phase_2;
    Min_Max_Obj.maxValuevoltage_phase_3 = maxValuevoltage_phase_3;
    Min_Max_Obj.minValuevoltage_phase_3 = minValuevoltage_phase_3;

    Min_Max_Obj.maxValuefrequency_array = maxValuefrequency_array;
    Min_Max_Obj.minValuefrequency_array = minValuefrequency_array;

    setMin_Max_Value(Min_Max_Obj);
  };



  useEffect(() => {
    console.log("test");
    if (deviceTHD.length > 0) {
      // let tHD_phase_1 =
      let tempthd1 = [];
      let tempthd2 = [];
      let tempthd3 = [];
      let thd_1 = "";
      let thd_2 = "";
      let thd_3 = "";

      deviceTHD[0].map((val, k) => {
        if (id === val.node) {
          thd_1 = val.value;
        }
        tempthd1.push(val.value);
      });

      deviceTHD[1].map((val, k) => {
        if (id === val.node) {
          thd_2 = val.value;
        }
        tempthd2.push(val.value);
      });

      deviceTHD[2].map((val, k) => {
        if (id === val.node) {
          thd_3 = val.value;
        }
        tempthd3.push(val.value);
      });

      let maxValuethd_phase_1 = tempthd1.length > 0 ? tempthd1.max() : 0;
      let minValuethd_phase_1 = tempthd1.length > 0 ? tempthd1.min() : 0;

      let maxValuethd_phase_2 = tempthd2.length > 0 ? tempthd2.max() : 0;
      let minValuethd_phase_2 = tempthd2.length > 0 ? tempthd2.min() : 0;

      let maxValuethd_phase_3 = tempthd3.length > 0 ? tempthd3.max() : 0;
      let minValuethd_phase_3 = tempthd3.length > 0 ? tempthd3.min() : 0;

      let maxValuethd_Avg = Number(
        Number(
          Number(maxValuethd_phase_1) +
          Number(maxValuethd_phase_2) +
          Number(maxValuethd_phase_3)
        ) / 3
      );
      let minValuethd_Avg = Number(
        Number(
          Number(minValuethd_phase_1) +
          Number(minValuethd_phase_2) +
          Number(minValuethd_phase_3)
        ) / 3
      );
      // console.log('tempthd1', tempthd1, tempthd2, tempthd3);
      let thd_Details_Obj = {};
      thd_Details_Obj.thd_1 = thd_1;
      thd_Details_Obj.thd_2 = thd_2;
      thd_Details_Obj.thd_3 = thd_3;
      thd_Details_Obj.maxValuethd_Avg = maxValuethd_Avg;
      thd_Details_Obj.minValuethd_Avg = minValuethd_Avg;
      thd_Details_Obj.maxValuethd_phase_1 = maxValuethd_phase_1;
      thd_Details_Obj.minValuethd_phase_1 = minValuethd_phase_1;
      thd_Details_Obj.maxValuethd_phase_2 = maxValuethd_phase_2;
      thd_Details_Obj.minValuethd_phase_2 = minValuethd_phase_2;
      thd_Details_Obj.maxValuethd_phase_3 = maxValuethd_phase_3;
      thd_Details_Obj.minValuethd_phase_3 = minValuethd_phase_3;

      setNodeTHDDetails(thd_Details_Obj);
    }
  }, [deviceTHD, id]);

  useEffect(() => {
    console.log("test");
    (async () => {
      if (id !== "" && id !== null) {
        let nodeName = id !== null ? generateNodeName(id) : "";
        let allNodeMetaData = nodeMetaData ? Object.entries(nodeMetaData) : [];
        let HistoryProject = await fetchDeviceHistoricalEnergy(
          projectListPayload
        );
       let equipmentType= allNodeMetaData.find((e) => e[0] === id)?.length > 0
        ? allNodeMetaData.find((e) => e[0] === id)[1]?.metadata
          ?.equipment_type
        : null;
        statevalue?.node?setEquipmentTtpe(statevalue?.node?.metadata?.equipment_type):setEquipmentTtpe(equipmentType)

        // console.log('HistoryProject', HistoryProject);
        if (
          HistoryProject &&
          HistoryProject[id] &&
          HistoryProject[id].length > 0
        ) {
          let tempDataArray = [];
          HistoryProject[id].map((val, key) => {
            let chartObj = {};
            let time = new Date(val.timestamp * 1000).toLocaleString("en-US", {
              hour: "numeric",
              hour12: true,
            });
            let longtime = new Date(val.timestamp * 1000).toLocaleString(
              "en-US",
              { hour: "numeric", minute: "numeric", hour12: true }
            );
            chartObj.name = time;
            chartObj.long_time = longtime;

            chartObj.rafc = moment
              .unix(val.timestamp)
              .format("yyyy-MM-DD'T'HH:mm:ss'Z'");
            chartObj.csv_rafc = moment
              .unix(val.timestamp)
              .format("yyyy-MM-DD'T'HH:mm:ss'Z'");
            chartObj.node_name =
              allNodeMetaData.find((e) => e[0] === id)?.length > 0
                ? allNodeMetaData.find((e) => e[0] === id)[1]?.metadata
                  ?.equiment_id
                : nodeName;
            // console.log('val:', val)
            let v = Number(Number(val.w1) + Number(val.w2) + Number(val.w3));
            let expVal = Number(
              Number(val.w1) + Number(val.w2) + Number(val.w3)
            ).toFixed(2);
            chartObj.node_id = id;

            if (v) {
              if (v == 0) {
                chartObj.uv = 0;
                chartObj.expVal = 0;
                chartObj.csv_expVal = 0;
              } else {
                let wattValue = v / 1000;
                let p = Number.parseFloat(wattValue).toFixed(2);
                // console.log('v----', v, p);
                chartObj.uv = Number(p);
                chartObj.expVal = Number(expVal);
                chartObj.csv_expVal = Number(expVal);
              }
            } else {
              chartObj.uv = 0;
              chartObj.expVal = 0;
            }
            tempDataArray.push(chartObj);
          });
          setProjectListChartData(tempDataArray);
          let lastItem = tempDataArray.pop();
          setProjectChartData(lastItem);
          setGraphMinMaxValues(HistoryProject[id]);

          let hplastArray = Object.values(HistoryProject[id]).pop().timestamp;
          var timeOptions = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          };
          let HPChartTime = new Date(hplastArray * 1000).toLocaleString(
            "en-ZA",
            timeOptions
          );
          setHPChartTime(HPChartTime);
        } else {
          setMin_Max_Value(DefaultMinMaxValues);
        }
      }
    })();
  }, [id]);

  useEffect(() => {
    console.log("test");
    const ignoreParams = ["thdv1", "thdv2", "thdv3", "thdi2", "thdi1", "thdi3"];
    const filterParam = nodeListParam?.filter((item) => !ignoreParams.includes(item.name.toLowerCase()));
    setFilterParamsValue(filterParam)
    let idsParam = filterParam?.map((data) => data.param_id);
    (async () => {
      const historyParamsChartData = await fetchHistoryProjectListForEquipmentDetail(
        [id],
        "include",
        idsParam
      );
      setCombinedHistoryParamsChartData(historyParamsChartData);
    })();
  }, [id]);

  useEffect(() => {
    console.log("test");
    if (combinedHistoryParamsChartData) {
      let chartParams = [];
      combinedHistoryParamsChartData?.forEach((historyProjectList) => {
        let nodeName = id !== null ? generateNodeName(id) : "";
        let allNodeMetaData = nodeMetaData ? Object.entries(nodeMetaData) : [];
        if (historyProjectList && historyProjectList.length > 0) {
          let tempDataArray = [];
          historyProjectList.map((val, key) => {
            let chartObj = {};
            let time = new Date(val.timestamp * 1000).toLocaleString("en-US", {
              hour: "numeric",
              hour12: true,
            });
            let longtime = new Date(val.timestamp * 1000).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            chartObj.name = time;
            chartObj.long_time = longtime;
            chartObj.timestamp = val.timestamp;
            chartObj.paramId = val.paramId;

            chartObj.rafc = moment
              .unix(val.timestamp)
              .format("yyyy-MM-DD'T'HH:mm:ss'Z'");
            chartObj.csv_rafc = moment
              .unix(val.timestamp)
              .format("yyyy-MM-DD'T'HH:mm:ss'Z'");

            chartObj.node_id = projectParam?.consumptionPatternId;
            chartObj.node_name = projectParam?.consumptionPatternId;

            let v = Number(Math.round(val.aggregate + "e2") + "e-2");
            if (val.aggregate) {
              v = val?.aggregate;
              let p = Number.parseFloat(v).toFixed(2);
              chartObj.uv = Number(p);
              chartObj.expVal = Number(p);
              chartObj.csv_expVal = Number(p);
            } else {
              chartObj.uv = 0;
              chartObj.expVal = 0;
              chartObj.csv_expVal = 0;
            }

            tempDataArray.push(chartObj);
          });
          chartParams.push(tempDataArray)

          let hplastArray = Object.values(historyProjectList).pop().timestamp;
          // setHplastTime(hplastArray);
          var timeOptions = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          };
          let HPChartTime = new Date(hplastArray * 1000).toLocaleString(
            "en-ZA",
            timeOptions
          );
          setHPChartTime(HPChartTime);
        } else {
          // setProjectListChartData([]);
          // setProjectChartData(0)
        }
      });
      let defaultData = []
      setParamsChartData(chartParams);
    }
  }, [combinedHistoryParamsChartData]);

  const copyId = (data) => {
    navigator.clipboard.writeText(data);
    ToastAlert({ msg: "Equipment id copied!", msgType: "info" });
    // AlertMessage({ msg: "Equipment id copied!" })
    // return;
  };


  const setChartTime = (data) => {
    let hplastArray = data.timestamp;
    var timeOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    let HPChartTime = new Date(hplastArray * 1000).toLocaleString(
      "en-ZA",
      timeOptions
    );
    return HPChartTime
  }


  const setChartTitle = (data) => {
    let nodeParam = nodeListParam.find(param => param.param_id === data.paramId);
    return nodeParam?.name
  }

  const onChangeDate = async (year, month) => {
    let yearValue = "";
    if (year?.target?.value) {
      yearValue = Number(year?.target?.value);
    } else {
      yearValue = Number(year);
    }

    let monthvalue = "";
    if (isNaN(month)) {
      monthvalue = Number(monthNames.indexOf(month?.target?.value));
    } else {
      monthvalue = Number(month);
    }
    setCurrentYear(yearValue);
    setCurrentMonth(monthvalue);
    setCurrentMonthVal(monthvalue + 1);

    var firstDay =
      Math.floor(
        new Date(
          Date.UTC(
            yearValue,
            monthvalue,
            1,
            0,
            0,
            0,
            0
          )
        ) / 1000
      );
    var lastDay =
      Math.floor(
        new Date(
          Date.UTC(
            yearValue,
            monthvalue + 1,
            0,
            23,
            59,
            59,
            999
          )
        ) / 1000
      );
    setFirstDayMonth(firstDay);
    setLastDayMonth(lastDay);

    setConsumptionPatternPayload({
      ...consumptionPatternPayload,
      from: firstDay,
      to: lastDay,
    });
    let consumptionPayload = {
      from: firstDay,
      to: lastDay,
      nodes: [id],
      type: "include"
    };

    let fetchdata = await fetchConsumptionPattern(consumptionPayload, false);
    console.log(fetchdata, 'fetchdata');
    setlocal_consumptionPattern(fetchdata)
    setReloadGraph(true);
  };

  const [componentList, setComponentList] = React.useState([
    { title: "Voltage (v)", value: "voltageValue" },
    { title: "Current (A)", value: "currentValue" },
    { title: "THD", value: "maximumTHD" },
    { title: "Frequency", value: "frequency" },
  ]);

  Array.prototype.max = function () {
    return Math.max.apply(null, this);
  };

  Array.prototype.min = function () {
    return Math.min.apply(null, this);
  };

  function sumAvg(tempArray) {
    let tempavgsum = 0;
    let finalSum = "";
    for (let i = 0; i < tempArray.length; i++) {
      tempavgsum += tempArray[i];
    }
    if (tempArray.length > 0) {
      finalSum = Number(tempavgsum / tempArray.length).toFixed(2);
    }
    return finalSum;
  }

  function getAllDaysInMonth(year, month) {
    const date = new Date(year, month, 1);
    const dates = [];
    while (date.getMonth() === month) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  const years = (startYear) => {
    var currentYear = new Date().getFullYear(),
      years = [];
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    years.sort((a, b) => (a > b ? -1 : 1));
    return years;
  };

  const CustomToolTipContent = ({ active, payload, label, color }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          bgcolor="#FFFFFF"
          border="0.25px solid #D5D5D5 !important"
          borderRadius={"13px"}
          boxShadow="5px 5px 10px rgba(106, 105, 105, 0.15)"
          padding={2}
        >
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              Time
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.payload?.long_time != ""
                ? payload[0]?.payload?.long_time
                : ""
                }`}
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              Power Consumed
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.value}`}
            </Typography>
          </Box>
        </Box>
      );
    }
    return null;
  };

  const CustomToolTipContentPC = ({ active, payload, label, color }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          bgcolor="#FFFFFF"
          border="0.25px solid #D5D5D5 !important"
          borderRadius={"13px"}
          boxShadow="5px 5px 10px rgba(106, 105, 105, 0.15)"
          padding={2}
        >
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              Day
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.payload?.name != "" ? payload[0]?.payload?.name : ""
                }`}
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              Power Consumed
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.value}kWh`}
            </Typography>
          </Box>
        </Box>
      );
    }
    return null;
  };

  const CustomToolTipContentPowerFector = ({
    active,
    payload,
    label,
    color,
  }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          bgcolor="#FFFFFF"
          border="0.25px solid #D5D5D5 !important"
          borderRadius={"13px"}
          boxShadow="5px 5px 10px rgba(106, 105, 105, 0.15)"
          padding={2}
        >
          <Box display="flex" gap={1} alignItems="center">
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.value}`}
            </Typography>
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              Active Equipments with
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              Power Factor
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.payload?.start} - ${payload[0]?.payload?.end}`}
            </Typography>
          </Box>
        </Box>
      );
    }
    return null;
  };

  const CustomToolTipContentcurrent = ({ active, payload, label, color }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          bgcolor="#FFFFFF"
          border="0.25px solid #D5D5D5 !important"
          borderRadius={"13px"}
          boxShadow="5px 5px 10px rgba(106, 105, 105, 0.15)"
          padding={2}
        >
          <Box display="flex" gap={1} alignItems="center">
            <Typography fontWeight={400} color={"#000"} fontSize={18}>
              {`${payload[0]?.value}`}
            </Typography>
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              total Devices
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              from
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.payload?.start}`}
            </Typography>
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              to
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.payload?.end}`}
            </Typography>
          </Box>
        </Box>
      );
    }
    return null;
  };

  const CustomToolTipContentvoltage = ({ active, payload, label, color }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          bgcolor="#FFFFFF"
          border="0.25px solid #D5D5D5 !important"
          borderRadius={"13px"}
          boxShadow="5px 5px 10px rgba(106, 105, 105, 0.15)"
          padding={2}
        >
          <Box display="flex" gap={1} alignItems="center">
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.value}`}
            </Typography>
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              Active Equipments with
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              Voltage
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.payload?.start} - ${payload[0]?.payload?.end}`}
            </Typography>
          </Box>
        </Box>
      );
    }
    return null;
  };

  const CustomToolTipContentTHD = ({ active, payload, label, color }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          bgcolor="#FFFFFF"
          border="0.25px solid #D5D5D5 !important"
          borderRadius={"13px"}
          boxShadow="5px 5px 10px rgba(106, 105, 105, 0.15)"
          padding={2}
        >
          <Box display="flex" gap={1} alignItems="center">
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.value}`}
            </Typography>
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              Active Equipments with
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              THD value
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.payload?.start} - ${payload[0]?.payload?.end}`}
            </Typography>
          </Box>
        </Box>
      );
    }
    return null;
  };

  const CustomToolTipContentfrequency = ({ active, payload, label, color }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          bgcolor="#FFFFFF"
          border="0.25px solid #D5D5D5 !important"
          borderRadius={"13px"}
          boxShadow="5px 5px 10px rgba(106, 105, 105, 0.15)"
          padding={2}
        >
          <Box display="flex" gap={1} alignItems="center">
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.value}`}
            </Typography>
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              Active Equipments with
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              fontWeight={400}
              color="#8D8D8D"
              fontSize={12}
              sx={{ wordWrap: "break-word" }}
              maxWidth="fit-content"
            >
              Frequency
            </Typography>
            <Typography fontWeight={400} color={color} fontSize={16}>
              {`${payload[0]?.payload?.start} - ${payload[0]?.payload?.end}`}
            </Typography>
          </Box>
        </Box>
      );
    }
    return null;
  };

  const convertToMegaOrKilo = (total, unit) => {
    if (total < 1000) {
      return `${total.toFixed(2)}${unit}`;
    } else if (total > 1000000) {
      return `${(total / 1000000).toFixed(2)}M${unit}`;
    } else if (total > 1000) {
      return `${(total / 1000).toFixed(2)}k${unit}`;
    }
  };

  const getTotalPower = apiResData?.data?.reduce(
    (p, c) => p + c?.w1 + c?.w2 + c?.w3,
    0
  );

  const totalPower = convertToMegaOrKilo(getTotalPower, "W");

  const getCurrent = apiResData?.data?.reduce(
    (p, c) => p + c?.i1 + c?.i2 + c?.i3,
    0
  );

  const totalCurrent = convertToMegaOrKilo(getCurrent, "A");

  const getPowerFactor = apiResData?.data
    ?.reduce((p, c) => p + (c?.pf1 + c?.pf2 + c?.pf3) / 3, 0)
    .toFixed(2);

  const getFrequency = apiResData?.data
    ?.reduce((p, c) => p + c.frequency, 0)
    .toFixed(2);

  const onClickBar = (e, graph) => {
    const filterOptions = {
      components: componentList.find((e) => e.value == graph),
      operation: "between",
      value: "",
      start: e.start,
      end: e.end,
      condition: "and",
    };
    let filterList = [filterOptions];

    let encodedFilter = encryptData(JSON.stringify(filterList));

    navigate("/equipments?filter=" + encodedFilter);
  };

  const expandGraph = () => {
    dispatch(
      setSaveIntervals({
        from: Math.floor(new Date().setHours(0, 0, 0, 0) / 1000),
        to: Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
      })
    );
    let obj = {
      paramid: [id],
      from: Math.floor(new Date().setHours(0, 0, 0, 0) / 1000),
      to: Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
      limit: 110000,
      order: "asc",
    };
    obj.parameter = "importkwh";
    obj.node = id;

    let encodedGraphData = encryptData(JSON.stringify(obj));
    navigate("/chart/view?filter=" + encodedGraphData);
  };

  useEffect(()=> {
      if (consumptionChartSelectedMonth !== null) {
      setCurrentYear(consumptionChartSelectedMonth.yearValue);
      setCurrentMonth(consumptionChartSelectedMonth.monthvalue);
      // setFirstDayMonth(consumptionChartSelectedDate.from);
      // setLastDayMonth(consumptionChartSelectedDate.to);
    }
  }, [consumptionChartSelectedMonth])


  useEffect(() => {
    console.log("test");
    (async () => {
      let payloads = {
        from: Math.floor(
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              1,
              0,
              0,
              0,
              0
            )
          ) / 1000
        ),
        to: Math.floor(
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0,
              23,
              59,
              59,
              999
            )
          ) / 1000
        ),
        nodes: [id],
        type: "include"
      };
      await fetchConsumptionPattern(payloads,false)
      // dispatch(setConsumptionChartSelectedDateAction(payloads))
    })();
  }, [])

  useEffect(() => {
    (async () => {
      console.log(local_consumptionPattern, 'local_consumptionPattern');
      if ((local_consumptionPattern && local_consumptionPattern !== undefined && local_consumptionPattern.length > 0 && local_consumptionPattern !== null) || reloadGraph === false) {
        let barchartData = []
        local_consumptionPattern?.forEach((data, i) => {
          const date = new Date(data?.timestamp * 1000);
          let mockdata = {

            name: date.getDate(),
            pv: parseFloat(data.aggregate?.toFixed(2))
          }
          barchartData.push(mockdata)
        })

        let consumptionAggregate = local_consumptionPattern?.map((data) => data.aggregate);
        let TotalAggregate = 0;
        consumptionAggregate?.map((value) => {
          return (
            TotalAggregate += value
          )
        })
        setCpTotalChartData(TotalAggregate?.toFixed(2));

        let curruntMonthCount = getAllDaysInMonth(currentYear, currentMonth);
        var tempdatearr = [];
        for (var i = 1; i < curruntMonthCount.length + 1; i++) {
          let tempObj = {};
          if (!barchartData.includes(i)) {
            tempObj.name = i;
            tempObj.pv = 0;
            tempdatearr.push(tempObj);
          }
        }
        const mainChartArray = [...barchartData, ...tempdatearr];

        const uniqueArrayOfObjects = mainChartArray.filter((obj, index, self) =>
          index === self.findIndex((item) => item.name === obj.name)
        );

        uniqueArrayOfObjects.sort(function (a, b) {
          return a.name - b.name;
        });



        setBarChartData(uniqueArrayOfObjects);
      }
      else {
        setBarChartData([])
        setCpTotalChartData("--")
      }
    })();
  }, [local_consumptionPattern, id])


  useEffect(() => {
    onChangeDate(currentYear, currentMonth)
  }, [])

  const styles = {
    yAxisLabel: {
      textAnchor: "middle", // You can adjust this value as needed
    },
  };
  console.log(
    // deviceTHD,
    // instEnergy,
    // historyProjectList,
    // nodeListParam,
    // deviceInstParam,
    // projectParam,
    // consumptionChartSelectedDate,
    // consumptionChartSelectedMonth,
    'barChartDatabarChartData');

  console.log(min_Max_Value, 'nodeTHDDetails');

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        bgcolor: "#F5F5F5",
      }}
    >
      <Toolbar />
      <ToastMessage />
      <Box my={3} mx={4}>
        <Box
          p="1%"
          mb="1%"
          // width="%"
          bgcolor={"#fff"}
          boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
          borderRadius={3}
        >
          <Grid
            container
            spacing={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip title={headersData.node_id} arrow placement="top">
                  <IconButton onClick={() => copyId(headersData.node_id)}>
                    <PriorityHighIcon
                      sx={{
                        borderRadius: "50%",
                        bgcolor: "#E6E6E6",
                        padding: 0.2,
                        color: "white",
                        fontSize: "13px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <span
                  style={{
                    textDecoration: "none",
                    color: "#1B53F9",
                    fontWeight: 500,
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  {headersData.node_name}
                </span>
                &nbsp;
               { statevalue.node?
                   checkEquipmetIsActive(statevalue.node.latestTimestamp * 1000) ?(
                       <FiberManualRecordIcon style={{ color: "#02F5A0" }} />
                              ) : (
                      <FiberManualRecordIcon style={{ color: "#FF4438" }} />
                  ): checkEquipmetIsActive(nodeDetails?.timestamp * 1000) ? (
                    <FiberManualRecordIcon style={{ color: "#02F5A0" }} />
                  ) : (
                    <FiberManualRecordIcon style={{ color: "#FF4438" }} />
                  )
                }
              </div>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: 700,
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.7)",
                    fontStyle: "normal",
                  }}
                >
                  Location
                </label>
                &nbsp;
                <p
                  style={{
                    fontWeight: 300,
                    fontSize: "10px",
                  }}
                >
                  {headersData.country}
                </p>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: 700,
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.7)",
                    fontStyle: "normal",
                  }}
                >
                  Description
                </label>
                &nbsp;
                <p
                  style={{
                    fontWeight: 300,
                    fontSize: "10px",
                  }}
                >
                  {headersData.description}
                </p>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: 700,
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.7)",
                    fontStyle: "normal",
                  }}
                >
                  Remarks
                </label>
                &nbsp;
                <p
                  style={{
                    fontWeight: 300,
                    fontSize: "10px",
                  }}
                >
                  {headersData.remarks}
                </p>
              </div>
            </Grid>
            <Grid item xs={1}>
            <SettingsOutlinedIcon
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{
              padding: 0.5,
              color: "#808080d1",
            }}
            style={{ cursor: "pointer" }}
            />
           <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
             >
           <MenuItem sx={{width: '100px'}}onClick={handleNavlgateAlert}>Alert</MenuItem>
           </Menu>
              
            </Grid>
          </Grid>
        </Box>
        
      {equipmentType!=="street_light"&&
        <Box display="flex" justifyContent="space-between">
          <Box
            p="1%"
            mb="1%"
            width="43%"
            bgcolor={"#fff"}
            boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
            borderRadius={5}
          >
            <Box display="flex" gap={3} alignItems="start">
              <BoltIcon
                sx={{
                  borderRadius: "50%",
                  bgcolor: "rgba(217, 217, 217, 0.2)",
                  padding: 0.5,
                }}
              />
              <Box width="100%">
                <div className="grid grid-cols-1 sm:grid-cols-2">
                  <div>
                    <Box display="flex" gap={1}>
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        color="rgba(0, 0, 0, 0.7)"
                      >
                        Total Power (Watt)
                      </Typography>
                      <Tooltip
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                        arrow
                        placement="right"
                      >
                        <PriorityHighIcon
                          sx={{
                            borderRadius: "50%",
                            bgcolor: "#E6E6E6",
                            padding: 0.2,
                            color: "white",
                            fontSize: "13px",
                          }}
                        />
                      </Tooltip>
                    </Box>
                    <Typography fontSize={11} fontWeight={300} color="#8D8D8D">
                      as of {hPChartTime}
                    </Typography>
                  </div>
                </div>
                <div className=" mt-5 mb-5">
                  <Box display="flex" justifyContent="space-between" gap={2}>
                    {/* <Box className="col-start-1 col-span-2  gap-2">
                                            <Typography
                                                component="span"
                                                fontSize={10}
                                                fontWeight={700}
                                                color="#971BF9"
                                            >
                                                75.33/100.33 kW
                                            </Typography>
                                        </Box> */}
                    <Box>
                      <Box>
                        <Box display="flex" gap={1}>
                          <Typography
                            fontSize={24}
                            fontWeight={700}
                          >
                            {projectChartData?.uv < 1 ? (
                              `${projectChartData?.uv * 1000}W`
                            ) : (
                              <React.Fragment>
                                {
                                  projectChartData?.uv
                                    ?.toFixed(2)
                                    ?.toString()
                                    ?.split(".")[0]
                                }
                                <Typography
                                  component="span"
                                  fontSize={17}
                                  fontWeight={600}
                                  className="Mediumlg:text-[13px]"
                                >
                                  .
                                  {
                                    projectChartData?.uv
                                      ?.toFixed(2)
                                      ?.toString()
                                      ?.split(".")[1]
                                  }
                                  kW
                                </Typography>
                              </React.Fragment>
                            )}

                            {/* {Number(projectChartData.uv ? projectChartData.uv : 0).toFixed(2)?.toString()?.split(".")[0]}
                                                        <Typography
                                                            component="span"
                                                            fontSize={12}
                                                            fontWeight={600}
                                                            color="#971BF9"
                                                        >
                                                            .{Number(projectChartData.uv ? projectChartData.uv : 0).toFixed(2)?.toString()?.split(".")[1]}
                                                            {(projectChartData?.uv > 1000 ? "W" : "kW")}
                                                        </Typography> */}
                          </Typography>
                          {/* <Box>
                                                        <Typography
                                                            color="#07D2B4"
                                                            fontWeight={400}
                                                            fontSize={10}
                                                        >
                                                            -7%
                                                            <TrendingDownIcon fontSize="10px" />
                                                        </Typography>
                                                        <Typography
                                                            color="#8D8D8D"
                                                            fontWeight={300}
                                                            fontSize={8}
                                                        >
                                                            vs previous 30 days
                                                        </Typography>
                                                    </Box>
                                                    <Tooltip
                                                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                                                        arrow
                                                        placement="right"
                                                    >
                                                        <PriorityHighIcon
                                                            sx={{
                                                                borderRadius: "50%",
                                                                bgcolor: "#E6E6E6",
                                                                padding: 0.2,
                                                                color: "white",
                                                                fontSize: "13px"
                                                            }}
                                                        />
                                                    </Tooltip> */}
                        </Box>
                      </Box>
                      {/* <Box>
                                                <Box display="flex" gap={1}>
                                                    <Typography
                                                        fontSize={24}
                                                        fontWeight={700}
                                                        color="#FFBC0F"
                                                    >
                                                        55
                                                        <Typography
                                                            component="span"
                                                            color="#FFBC0F80"
                                                        >
                                                            kWh
                                                        </Typography>
                                                    </Typography>
                                                    <Box>
                                                        <Typography
                                                            color="#07D2B4"
                                                            fontWeight={400}
                                                            fontSize={10}
                                                        >
                                                            -7%
                                                            <TrendingDownIcon fontSize="10px" />
                                                        </Typography>
                                                        <Typography
                                                            color="#8D8D8D"
                                                            fontWeight={300}
                                                            fontSize={8}
                                                        >
                                                            vs previous 30 days
                                                        </Typography>
                                                    </Box>
                                                    <Tooltip
                                                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                                                        arrow
                                                        placement="right"
                                                    >
                                                        <PriorityHighIcon
                                                            sx={{
                                                                borderRadius: "50%",
                                                                bgcolor: "#E6E6E6",
                                                                padding: 0.2,
                                                                color: "white",
                                                                fontSize: "13px"
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            </Box> */}
                    </Box>

                    {/* <Box className="col-start-1 col-span-2  gap-2">
                                            <Typography
                                                component="span"
                                                fontSize={10}
                                                fontWeight={700}
                                                color="#FFBC0F"
                                            >
                                                75.33/100.33 kW
                                            </Typography>
                                        </Box> */}

                    <Box>
                      <CSVExportBtn
                        headers={csvHeader}
                        csvData={
                          projectListChartData ? projectListChartData : []
                        }
                        label={
                          <FileDownloadOutlinedIcon
                            sx={{
                              padding: 0.5,
                              color: "#808080d1",
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        }
                        prifix="_total_power"
                      />
                      <OpenInFullOutlinedIcon
                        sx={{
                          padding: 0.5,
                          color: "#808080d1",
                        }}
                        style={{ cursor: "pointer" }}
                        onClick={() => expandGraph()}
                      />
                    </Box>
                  </Box>
                </div>
              </Box>
            </Box>
            <ResponsiveContainer width="100%" height="70%">
              <AreaChart
                data={projectListChartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <defs>
                  <linearGradient
                    id="colorAreaChart"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="5%"
                      stopColor="rgba(151, 27, 249, 1)"
                      stopOpacity={0.1}
                    />
                    <stop
                      offset="95%"
                      stopColor="rgba(151, 27, 249, 0.09)"
                      stopOpacity={0.1}
                    />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="name"
                  fontSize={14}
                  fontWeight={400}
                  tick={{ fill: "#BEBEBE" }}
                  interval={200}
                  tickLine={false}
                  axisLine={false}
                >
                  <Label
                    offset={-4}
                    value="Time"
                    position="insideBottom"
                    fontSize={11}
                    fill="#616161"
                    fontWeight={300}
                  />
                </XAxis>
                <YAxis
                  fontSize={14}
                  fontWeight={400}
                  tick={{ fill: "#971BF9" }}
                  tickLine={false}
                  axisLine={false}
                  label={{
                    value: "Power in Kilo-Watt (kW)",
                    angle: -90,
                    position: "insideLeft",
                    fontWeight: 300,
                    fontSize: 11,
                    offset: -4,
                    fill: "#616161",
                    style: styles.yAxisLabel,
                  }}
                />
                <ReChartToolTip
                  wrapperStyle={{ outline: "none" }}
                  cursor={false}
                  content={<CustomToolTipContent color="#971BF9" />}
                />
                <CartesianGrid vertical={false} stroke="#DDD" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="rgba(151, 27, 249, 1)"
                  fillOpacity={1}
                  fill="url(#colorAreaChart)"
                />
                {/* <Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" /> */}
              </AreaChart>
            </ResponsiveContainer>
          </Box>
          <Box
            p="1%"
            mb="1%"
            width="55%"
            bgcolor={"#fff"}
            boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
            borderRadius={5}
          >
            <Box display="flex" gap={3} alignItems="start">
              <QueryStatsIcon
                sx={{
                  borderRadius: "50%",
                  bgcolor: "rgba(217, 217, 217, 0.2)",
                  padding: 0.5,
                }}
              />
              <Box width="100%">
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Box display="flex" gap={1}>
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        color="rgba(0, 0, 0, 0.7)"
                      >
                        Consumption Pattern
                      </Typography>
                      <Tooltip
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                        arrow
                        placement="right"
                      >
                        <PriorityHighIcon
                          sx={{
                            borderRadius: "50%",
                            bgcolor: "#E6E6E6",
                            padding: 0.2,
                            color: "white",
                            fontSize: "13px",
                          }}
                        />
                      </Tooltip>
                    </Box>
                    <Typography fontSize={11} fontWeight={300} color="#8D8D8D">
                      as of {hPChartTime}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <Select
                        displayEmpty
                        value={currentYear}
                        onChange={(value) => onChangeDate(value, currentMonth)}
                        input={
                          <OutlinedInput
                            sx={{
                              border: "1px solid rgba(0, 0, 0, 0.1)",
                              borderRadius: "14.5px",
                              "& fieldset": { border: "none" },
                              color: "#7F7F7F",
                              fontSize: "14px",
                              fontWeight: 500,
                              "& .MuiSvgIcon-root": {
                                color: "#7F7F7F",
                              },
                              "& em": {
                                fontStyle: "normal",
                              },
                            }}
                          />
                        }
                        renderValue={(selected) => {
                          if (!selected) {
                            return <em>Year</em>;
                          }
                          return selected;
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 48 * 4.5 + 8,
                              width: 250,
                            },
                          },
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {years(2019 - 20).map((yearOption) => (
                          <MenuItem key={yearOption} value={yearOption}>
                            {yearOption}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <Select
                        displayEmpty
                        value={monthNames[currentMonth]}
                        onChange={(value) => onChangeDate(currentYear, value)}
                        input={
                          <OutlinedInput
                            sx={{
                              border: "1px solid rgba(0, 0, 0, 0.1)",
                              borderRadius: "14.5px",
                              "& fieldset": { border: "none" },
                              color: "#7F7F7F",
                              fontSize: "14px",
                              fontWeight: 500,
                              "& .MuiSvgIcon-root": {
                                color: "#7F7F7F",
                              },
                              "& em": {
                                fontStyle: "normal",
                              },
                            }}
                          />
                        }
                        renderValue={(selected) => {
                          if (!selected) {
                            return <em>Month</em>;
                          }
                          return selected;
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 48 * 4.5 + 8,
                              width: 250,
                            },
                          },
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {monthNames.map((monthSelected) => (
                          <MenuItem key={monthSelected} value={monthSelected}>
                            {monthSelected}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box display="flex" >



                  <Box
                    className="flex justify-between w-full"
                    display="flex"
                    mb={"20px"}
                  >
                    <Box gap={1} display="flex" >

                      <Typography
                        className="text-[24px] Mediumlg:text-[16px]"
                        fontWeight={700}
                        color="#000000"
                      >
                        {cpTotalChartData}
                        <Typography
                          component="span"
                          color="rgba(0, 0, 0, 0.5)"
                          fontWeight={600}
                          className="Mediumlg:text-[13px]"
                        >
                          kWh
                        </Typography>
                      </Typography>
                      <Tooltip
                        title="Represents the total units consumption of a month/year"
                        arrow
                        placement="right"
                      >
                        <PriorityHighIcon
                          sx={{
                            borderRadius: "50%",
                            bgcolor: "#E6E6E6",
                            padding: 0.2,
                            color: "white",
                            fontSize: "13px",
                          }}
                        />
                      </Tooltip>

                    </Box>
                    <CSVExportBtn
                      headers={csvHeader}
                      csvData={
                        consumptionPatternChartData
                          ? consumptionPatternChartData
                          : []
                      }
                      label={
                        <FileDownloadOutlinedIcon
                          sx={{
                            padding: 0.5,
                            color: "#808080d1",
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      }
                      prifix="_consumption_pattern"
                    />


                  </Box>


                </Box>
              </Box>
            </Box>
            <ResponsiveContainer width="100%" height="70%">
              <BarChart
                data={barChartData}
                width={500}
                height={500}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <defs>
                  <linearGradient
                    id="colorBarChart"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100%"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#1D47FF" />
                    <stop offset="1" stopColor="#04E3AA" />
                  </linearGradient>
                  <linearGradient
                    id="colorBarChart2"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100%"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stopColor="#FF4438" />
                    <stop offset="1" stopColor="#FFBB38" />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="name"
                  interval={1}
                  fontSize={14}
                  fontWeight={400}
                  tick={{ fill: "#BEBEBE" }}
                  tickLine={false}
                  axisLine={false}
                >
                  <Label
                    offset={-4}
                    value={monthNames[currentMonth] + " " + currentYear}
                    position="insideBottom"
                    fontSize={11}
                    fill="#616161"
                    fontWeight={300}
                  />
                </XAxis>
                <YAxis
                  allowDataOverflow={true}
                  fontSize={14}
                  fontWeight={400}
                  tick={{ fill: "#464646" }}
                  tickLine={false}
                  axisLine={false}
                  label={{
                    value: "Power Consumption (kWh)",
                    angle: -90,
                    position: "insideLeft",
                    fontWeight: 300,
                    fontSize: 11,
                    offset: -4,
                    fill: "#616161",
                    style: styles.yAxisLabel,
                  }}
                />
                <ReChartToolTip
                  wrapperStyle={{ outline: "none" }}
                  cursor={false}
                  content={<CustomToolTipContentPC color="#1770EA" />}
                />
                <CartesianGrid
                  vertical={false}
                  stroke="rgba(29, 33, 37, 0.4)"
                  strokeOpacity={0.2}
                />
                <Bar
                  dataKey="pv"
                  fill="url(#colorBarChart)"
                  barSize={9}
                  radius={[10, 10, 0, 0]}
                >
                  {/* {consumptionPatternChartData.map((entry, index) => {
                    return (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          Math.sign(entry.pv) == 1
                            ? "url(#colorBarChart)"
                            : "url(#colorBarChart2)"
                        }
                      />
                    );
                  })} */}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>}
      </Box>
      {equipmentType!=="street_light"&&
      <Box my={3} mx={4}>
        <Box display="flex" justifyContent="space-between" height="100%">
          <Box
            p="1%"
            mb="1%"
            width="19%"
            bgcolor={"#fff"}
            boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
            borderRadius={5}
          >
            <Box
              display="flex"
              gap={1}
              alignItems="start"
              flexDirection="column"
            >
              <Box display="flex" gap={1}>
                <BoltIcon
                  sx={{
                    borderRadius: "50%",
                    bgcolor: "rgba(217, 217, 217, 0.2)",
                    padding: 0.5,
                  }}
                />
                <Box display="flex" gap={1}>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color="rgba(0, 0, 0, 0.7)"
                  >
                    Current (A)
                  </Typography>
                  <Tooltip
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                    arrow
                    placement="right"
                  >
                    <PriorityHighIcon
                      sx={{
                        borderRadius: "50%",
                        bgcolor: "#E6E6E6",
                        padding: 0.2,
                        color: "white",
                        fontSize: "13px",
                      }}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Box width="100%" height="70%">
              <table
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "15px",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <th></th>
                  <th
                    style={{
                      fontWeight: "300",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    Min.
                  </th>
                  <th
                    style={{
                      fontWeight: "600",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "2px",
                      }}
                    >
                      <span>Live Value</span>
                      <Tooltip
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                        arrow
                        placement="right"
                      >
                        <PriorityHighIcon
                          sx={{
                            borderRadius: "50%",
                            bgcolor: "#E6E6E6",
                            padding: 0.2,
                            color: "white",
                            fontSize: "13px",
                          }}
                        />
                      </Tooltip>
                    </div>
                  </th>
                  <th
                    style={{
                      fontWeight: "300",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    Max.
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#BEBEBE",
                      }}
                    >
                      Average
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                      }}
                    >
                      {Number(min_Max_Value?.minValuecurrent_Avg).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#000000",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                      }}
                    >
                      {Number(
                        Number(
                          Number(nodeDetails?.i1) +
                          Number(nodeDetails?.i2) +
                          Number(nodeDetails?.i3)
                        ) / 3
                      ).toFixed(2)}
                      <small style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                        <span style={{ fontSize: "20px" }}>A</span>
                      </small>
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                      }}
                    >
                      {Number(min_Max_Value?.maxValuecurrent_Avg).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#FF4438",
                      }}
                    >
                      Phase 01
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.minValuecurrent_phase_1).toFixed(
                        2
                      )}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#000000",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(nodeDetails?.i1).toFixed(2)}
                      <small style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                        <span style={{ fontSize: "20px" }}>A</span>
                      </small>
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.maxValuecurrent_phase_1).toFixed(
                        2
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#FFBC0F",
                      }}
                    >
                      Phase 02
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.minValuecurrent_phase_2).toFixed(
                        2
                      )}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#FFBC0F",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(nodeDetails?.i2).toFixed(2)}
                      <small style={{ color: "#FFBC0F" }}>
                        <span style={{ fontSize: "20px" }}>A</span>
                      </small>
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.maxValuecurrent_phase_2).toFixed(
                        2
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#1B53F9",
                      }}
                    >
                      Phase 03
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.minValuecurrent_phase_3).toFixed(
                        2
                      )}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#000000",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(nodeDetails?.i3).toFixed(2)}
                      <small style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                        <span style={{ fontSize: "20px" }}>A</span>
                      </small>
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.maxValuecurrent_phase_3).toFixed(
                        2
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
          <Box
            p="1%"
            mb="1%"
            width="19%"
            bgcolor={"#fff"}
            boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
            borderRadius={5}
          >
            <Box
              display="flex"
              gap={1}
              alignItems="start"
              flexDirection="column"
            >
              <Box display="flex" gap={1}>
                <BatteryCharging20Icon
                  sx={{
                    borderRadius: "50%",
                    bgcolor: "rgba(217, 217, 217, 0.2)",
                    padding: 0.5,
                  }}
                />
                <Box display="flex" gap={1}>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color="rgba(0, 0, 0, 0.7)"
                  >
                    Power Factor
                  </Typography>
                  <Tooltip
                    title="Power Factor Graph Showes Avrage Devices"
                    arrow
                    placement="right"
                  >
                    <PriorityHighIcon
                      sx={{
                        borderRadius: "50%",
                        bgcolor: "#E6E6E6",
                        padding: 0.2,
                        color: "white",
                        fontSize: "13px",
                      }}
                    />
                  </Tooltip>
                </Box>
              </Box>
              {/* <Box display="flex" justifyContent="space-between">
                                <Box display="flex" gap={1}>
                                    <Typography
                                        fontSize={24}
                                        fontWeight={700}
                                        color="#FF4438"
                                    >
                                        {avgOfPowerFectorData}
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            color="#8D8D8D"
                                            fontWeight={300}
                                            fontSize={8}
                                        >
                                            Average Value
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box> */}
            </Box>
            <Box width="100%" height="70%">
              <table
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "15px",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <th></th>
                  <th
                    style={{
                      fontWeight: "300",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    Min.
                  </th>
                  <th
                    style={{
                      fontWeight: "600",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "2px",
                      }}
                    >
                      <span>Live Value</span>
                      <Tooltip
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                        arrow
                        placement="right"
                      >
                        <PriorityHighIcon
                          sx={{
                            borderRadius: "50%",
                            bgcolor: "#E6E6E6",
                            padding: 0.2,
                            color: "white",
                            fontSize: "13px",
                          }}
                        />
                      </Tooltip>
                    </div>
                  </th>
                  <th
                    style={{
                      fontWeight: "300",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    Max.
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#BEBEBE",
                      }}
                    >
                      Average
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                      }}
                    >
                      {Number(min_Max_Value?.minValuepf_Avg).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#FF4438",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                        color: `${getBarColor(
                          SLUG_POWER_FACTOR_BARCHART,
                          Number(
                            Number(
                              Number(nodeDetails?.pf1) +
                              Number(nodeDetails?.pf2) +
                              Number(nodeDetails?.pf3)
                            ) / 3
                          )
                        )}`,
                      }}
                    >
                      {Number(
                        Number(
                          Number(nodeDetails?.pf1) +
                          Number(nodeDetails?.pf2) +
                          Number(nodeDetails?.pf3)
                        ) / 3
                      ).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                      }}
                    >
                      {Number(min_Max_Value?.maxValuepf_Avg).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#FF4438",
                      }}
                    >
                      Phase 01
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.minValuepf_phase_1).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#FF4438",
                        fontStyle: "normal",
                        color: `${getBarColor(
                          SLUG_POWER_FACTOR_BARCHART,
                          Number(nodeDetails?.pf1)
                        )}`,
                      }}
                    >
                      {Number(nodeDetails?.pf1).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.maxValuepf_phase_1).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#FFBC0F",
                      }}
                    >
                      Phase 02
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.minValuepf_phase_2).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#FF4438",
                        fontStyle: "normal",
                        color: `${getBarColor(
                          SLUG_POWER_FACTOR_BARCHART,
                          Number(nodeDetails?.pf2)
                        )}`,
                      }}
                    >
                      {Number(nodeDetails?.pf2).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.maxValuepf_phase_2).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#1B53F9",
                      }}
                    >
                      Phase 03
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.minValuepf_phase_3).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#04E3AA",
                        fontStyle: "normal",
                        color: `${getBarColor(
                          SLUG_POWER_FACTOR_BARCHART,
                          Number(nodeDetails?.pf3)
                        )}`,
                      }}
                    >
                      {Number(nodeDetails?.pf3).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.maxValuepf_phase_3).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
          <Box
            p="1%"
            mb="1%"
            width="19%"
            bgcolor={"#fff"}
            boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
            borderRadius={5}
          >
            <Box
              display="flex"
              gap={1}
              alignItems="start"
              flexDirection="column"
            >
              <Box display="flex" gap={1}>
                <MonitorHeartIcon
                  sx={{
                    borderRadius: "50%",
                    bgcolor: "rgba(217, 217, 217, 0.2)",
                    padding: 0.5,
                  }}
                />
                <Box display="flex" gap={1}>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color="rgba(0, 0, 0, 0.7)"
                  >
                    THD
                  </Typography>
                  <Tooltip
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                    arrow
                    placement="right"
                  >
                    <PriorityHighIcon
                      sx={{
                        borderRadius: "50%",
                        bgcolor: "#E6E6E6",
                        padding: 0.2,
                        color: "white",
                        fontSize: "13px",
                      }}
                    />
                  </Tooltip>
                </Box>
              </Box>
              {/* <Box display="flex" justifyContent="space-between">
                                <Box display="flex" gap={1}>
                                    <Typography
                                        fontSize={24}
                                        fontWeight={700}
                                        color="#FF4438"
                                    >
                                        {avgOfTHDData}%
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            color="#8D8D8D"
                                            fontWeight={300}
                                            fontSize={8}
                                        >
                                            Average Value
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box> */}
            </Box>
            <Box width="100%" height="70%">
              <table
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "15px",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <th></th>
                  <th
                    style={{
                      fontWeight: "300",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    Min.
                  </th>
                  <th
                    style={{
                      fontWeight: "600",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "2px",
                      }}
                    >
                      <span>Live Value</span>
                      <Tooltip
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                        arrow
                        placement="right"
                      >
                        <PriorityHighIcon
                          sx={{
                            borderRadius: "50%",
                            bgcolor: "#E6E6E6",
                            padding: 0.2,
                            color: "white",
                            fontSize: "13px",
                          }}
                        />
                      </Tooltip>
                    </div>
                  </th>
                  <th
                    style={{
                      fontWeight: "300",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    Max.
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#BEBEBE",
                      }}
                    >
                      Average
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                      }}
                    >
                      {Number(nodeTHDDetails?.minValuethd_Avg).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#FF4438",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                        color: `${getBarColor(
                          SLUG_THD_BARCHART,
                          Number(
                            Number(
                              Number(nodeTHDDetails?.thd_1) +
                              Number(nodeTHDDetails?.thd_2) +
                              Number(nodeTHDDetails?.thd_3)
                            ) / 3
                          )
                        )}`,
                      }}
                    >
                      {Number(
                        Number(
                          Number(nodeTHDDetails?.thd_1) +
                          Number(nodeTHDDetails?.thd_2) +
                          Number(nodeTHDDetails?.thd_3)
                        ) / 3
                      ).toFixed(2)}
                      <span style={{ fontSize: "20px" }}>%</span>
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                      }}
                    >
                      {Number(nodeTHDDetails?.maxValuethd_Avg).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#FF4438",
                      }}
                    >
                      Phase 01
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(nodeTHDDetails?.minValuethd_phase_1).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#FF4438",
                        fontStyle: "normal",
                        color: `${getBarColor(
                          SLUG_THD_BARCHART,
                          Number(nodeTHDDetails?.thd_1)
                        )}`,
                      }}
                    >
                      {Number(nodeTHDDetails?.thd_1).toFixed(2)}
                      <span style={{ fontSize: "20px" }}>%</span>
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(nodeTHDDetails?.maxValuethd_phase_1).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#FFBC0F",
                      }}
                    >
                      Phase 02
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(nodeTHDDetails?.minValuethd_phase_2).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#FF4438",
                        fontStyle: "normal",
                        color: `${getBarColor(
                          SLUG_THD_BARCHART,
                          Number(nodeTHDDetails?.thd_2)
                        )}`,
                      }}
                    >
                      {Number(nodeTHDDetails?.thd_2).toFixed(2)}
                      <span style={{ fontSize: "20px" }}>%</span>
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(nodeTHDDetails?.maxValuethd_phase_2).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#1B53F9",
                      }}
                    >
                      Phase 03
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(nodeTHDDetails?.minValuethd_phase_3).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#04E3AA",
                        fontStyle: "normal",
                        color: `${getBarColor(
                          SLUG_THD_BARCHART,
                          Number(nodeTHDDetails?.thd_3)
                        )}`,
                      }}
                    >
                      {Number(nodeTHDDetails?.thd_3).toFixed(2)}
                      <span style={{ fontSize: "20px" }}>%</span>
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(nodeTHDDetails?.maxValuethd_phase_3).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
          <Box
            p="1%"
            mb="1%"
            width="19%"
            bgcolor={"#fff"}
            boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
            borderRadius={5}
          >
            <Box
              display="flex"
              gap={1}
              alignItems="start"
              flexDirection="column"
            >
              <Box display="flex" gap={1}>
                <GraphicEqIcon
                  sx={{
                    borderRadius: "50%",
                    bgcolor: "rgba(217, 217, 217, 0.2)",
                    padding: 0.5,
                  }}
                />
                <Box display="flex" gap={1}>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color="rgba(0, 0, 0, 0.7)"
                  >
                    Frequency
                  </Typography>
                  <Tooltip
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                    arrow
                    placement="right"
                  >
                    <PriorityHighIcon
                      sx={{
                        borderRadius: "50%",
                        bgcolor: "#E6E6E6",
                        padding: 0.2,
                        color: "white",
                        fontSize: "13px",
                      }}
                    />
                  </Tooltip>
                </Box>
              </Box>
              {/* <Box display="flex" justifyContent="space-between">
                                <Box display="flex" gap={1}>
                                    <Typography
                                        fontSize={24}
                                        fontWeight={700}
                                        color="#07D7B1"
                                    >
                                        {avgOfFrequencyData}Hz
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            color="#8D8D8D"
                                            fontWeight={300}
                                            fontSize={8}
                                        >
                                            Average Value
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box> */}
            </Box>
            <Box width="100%" height="70%">
              <table
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "15px",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <th></th>
                  <th
                    style={{
                      fontWeight: "300",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    Min.
                  </th>
                  <th
                    style={{
                      fontWeight: "600",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "2px",
                      }}
                    >
                      <span>Live Value</span>
                      <Tooltip
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                        arrow
                        placement="right"
                      >
                        <PriorityHighIcon
                          sx={{
                            borderRadius: "50%",
                            bgcolor: "#E6E6E6",
                            padding: 0.2,
                            color: "white",
                            fontSize: "13px",
                          }}
                        />
                      </Tooltip>
                    </div>
                  </th>
                  <th
                    style={{
                      fontWeight: "300",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    Max.
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#BEBEBE",
                      }}
                    >
                      Average
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                      }}
                    >
                      {Number(min_Max_Value?.minValuefrequency_array).toFixed(
                        2
                      )}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#FF4438",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                        color: `${getBarColor(
                          SLUG_FREQUENCY_BARCHART,
                          Number(nodeDetails?.frequency)
                        )}`,
                      }}
                    >
                      {Number(nodeDetails?.frequency).toFixed(2)}
                      <span style={{ fontSize: "20px" }}>Hz</span>
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                      }}
                    >
                      {Number(min_Max_Value?.maxValuefrequency_array).toFixed(
                        2
                      )}
                    </td>
                  </tr>
                  {/* <tr>
                                        <td style={{ fontWeight: '700', fontSize: '8px', color: '#FF4438' }}>Phase 01</td>
                                        <td style={{ fontWeight: '700', fontSize: '16px', color: 'rgba(0, 0, 0, 0.5)', fontStyle: 'normal' }}>00</td>
                                        <td style={{ fontWeight: '700', fontSize: '29px', color: '#FF4438', fontStyle: 'normal' }}>0.23</td>
                                        <td style={{ fontWeight: '700', fontSize: '16px', color: 'rgba(0, 0, 0, 0.5)', fontStyle: 'normal' }}>01</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: '700', fontSize: '8px', color: '#FFBC0F' }}>Phase 02</td>
                                        <td style={{ fontWeight: '700', fontSize: '16px', color: 'rgba(0, 0, 0, 0.5)', fontStyle: 'normal' }}>00</td>
                                        <td style={{ fontWeight: '700', fontSize: '29px', color: '#FF4438', fontStyle: 'normal' }}>0.55</td>
                                        <td style={{ fontWeight: '700', fontSize: '16px', color: 'rgba(0, 0, 0, 0.5)', fontStyle: 'normal' }}>0.9</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: '700', fontSize: '8px', color: '#1B53F9' }}>Phase 03</td>
                                        <td style={{ fontWeight: '700', fontSize: '16px', color: 'rgba(0, 0, 0, 0.5)', fontStyle: 'normal' }}>00</td>
                                        <td style={{ fontWeight: '700', fontSize: '29px', color: '#04E3AA', fontStyle: 'normal' }}>0.9</td>
                                        <td style={{ fontWeight: '700', fontSize: '16px', color: 'rgba(0, 0, 0, 0.5)', fontStyle: 'normal' }}>0.9</td>
                                    </tr> */}
                </tbody>
              </table>
            </Box>
          </Box>
          <Box
            p="1%"
            mb="1%"
            width="19%"
            bgcolor={"#fff"}
            boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
            borderRadius={5}
          >
            <Box
              display="flex"
              gap={1}
              alignItems="start"
              flexDirection="column"
            >
              <Box display="flex" gap={1}>
                <ElectricMeterIcon
                  sx={{
                    borderRadius: "50%",
                    bgcolor: "rgba(217, 217, 217, 0.2)",
                    padding: 0.5,
                  }}
                />
                <Box display="flex" gap={1}>
                  <Typography
                    fontSize={14}
                    fontWeight={500}
                    color="rgba(0, 0, 0, 0.7)"
                  >
                    Voltage
                  </Typography>
                  <Tooltip
                    title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                    arrow
                    placement="right"
                  >
                    <PriorityHighIcon
                      sx={{
                        borderRadius: "50%",
                        bgcolor: "#E6E6E6",
                        padding: 0.2,
                        color: "white",
                        fontSize: "13px",
                      }}
                    />
                  </Tooltip>
                </Box>
              </Box>
              {/* <Box display="flex" justifyContent="space-between">
                                <Box display="flex" gap={1}>
                                    <Typography
                                        fontSize={24}
                                        fontWeight={700}
                                        color="#07D7B1"
                                    >
                                        {avgOfVoltageData}
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            color="#8D8D8D"
                                            fontWeight={300}
                                            fontSize={8}
                                        >
                                            Average Value
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box> */}
            </Box>
            <Box width="100%" height="70%">
              <table
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "15px",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <th></th>
                  <th
                    style={{
                      fontWeight: "300",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    Min.
                  </th>
                  <th
                    style={{
                      fontWeight: "600",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "2px",
                      }}
                    >
                      <span>Live Value</span>
                      <Tooltip
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                        arrow
                        placement="right"
                      >
                        <PriorityHighIcon
                          sx={{
                            borderRadius: "50%",
                            bgcolor: "#E6E6E6",
                            padding: 0.2,
                            color: "white",
                            fontSize: "13px",
                          }}
                        />
                      </Tooltip>
                    </div>
                  </th>
                  <th
                    style={{
                      fontWeight: "300",
                      fontSize: "8px",
                      color: "#8D8D8D",
                    }}
                  >
                    Max.
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#BEBEBE",
                      }}
                    >
                      Average
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                      }}
                    >
                      {Number(min_Max_Value?.minValuevoltage_Avg).toFixed(2)}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#FF4438",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                        color: `${getBarColor(
                          SLUG_VOLTAGE_BARCHART,
                          Number(
                            Number(
                              Number(nodeDetails?.v1) +
                              Number(nodeDetails?.v2) +
                              Number(nodeDetails?.v3)
                            ) / 3
                          )
                        )}`,
                      }}
                    >
                      {Number(
                        Number(
                          Number(nodeDetails?.v1) +
                          Number(nodeDetails?.v2) +
                          Number(nodeDetails?.v3)
                        ) / 3
                      ).toFixed(2)}
                      <span style={{ fontSize: "20px" }}>v</span>
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                        borderBottom: "1px solid rgb(215 217 219)",
                      }}
                    >
                      {Number(min_Max_Value?.maxValuevoltage_Avg).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#FF4438",
                      }}
                    >
                      Phase 01
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.minValuevoltage_phase_1).toFixed(
                        2
                      )}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#FF4438",
                        fontStyle: "normal",
                        color: `${getBarColor(
                          SLUG_VOLTAGE_BARCHART,
                          Number(nodeDetails?.v1)
                        )}`,
                      }}
                    >
                      {Number(nodeDetails?.v1).toFixed(2)}
                      <span style={{ fontSize: "20px" }}>v</span>
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.maxValuevoltage_phase_1).toFixed(
                        2
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#FFBC0F",
                      }}
                    >
                      Phase 02
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.minValuevoltage_phase_2).toFixed(
                        2
                      )}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#FF4438",
                        fontStyle: "normal",
                        color: `${getBarColor(
                          SLUG_VOLTAGE_BARCHART,
                          Number(nodeDetails?.v2)
                        )}`,
                      }}
                    >
                      {Number(nodeDetails?.v2).toFixed(2)}
                      <span style={{ fontSize: "20px" }}>v</span>
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.maxValuevoltage_phase_2).toFixed(
                        2
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "8px",
                        color: "#1B53F9",
                      }}
                    >
                      Phase 03
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.minValuevoltage_phase_3).toFixed(
                        2
                      )}
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "25px",
                        color: "#04E3AA",
                        fontStyle: "normal",
                        color: `${getBarColor(
                          SLUG_VOLTAGE_BARCHART,
                          Number(nodeDetails?.v3)
                        )}`,
                      }}
                    >
                      {Number(nodeDetails?.v3).toFixed(2)}
                      <span style={{ fontSize: "20px" }}>v</span>
                    </td>
                    <td
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                      }}
                    >
                      {Number(min_Max_Value?.maxValuevoltage_phase_3).toFixed(
                        2
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Box>}

      <Box display="flex" flexWrap="wrap" width="100%" paddingLeft="32px" paddingRight="32px" justifyContent="space-between">
        {paramsChartData?.map((data, index) => {
        function generateTimestamp(hour, minute) {
          const currentDate = new Date();
          currentDate.setHours(hour, minute, 0, 0);
          return Math.floor(currentDate.getTime() / 1000);
      }
        let existingTimeSlots = [];
      
      for (let hour = 6; hour <= 18; hour++) {
          const timestamp1 = generateTimestamp(hour, 0);
          const rafc = "";
          let timeSlot = {
              name: `${hour % 12 === 0 ? 12 : hour % 12} ${hour < 12 ? 'AM' : 'PM'}`,
              long_time: `${hour < 10 ? '0' : ''}${hour % 12 === 0 ? 12 : hour % 12}:00 ${hour < 12 ? 'AM' : 'PM'}`,
              timestamp: timestamp1,
              paramId: '',
              // csv_expVal: '',
              rafc: rafc
          };
                existingTimeSlots.push(timeSlot);
      }
      
            const mainChartArray = [...data, ...existingTimeSlots];

            const uniqueArrayOfObjects = mainChartArray.filter((obj, index, self) =>
            index === self.findIndex((item) => item.long_time === obj.long_time)
          );

          function compareTimes(a, b) {
            const timeA = new Date('1970-01-01 ' + a.long_time);
            const timeB = new Date('1970-01-01 ' + b.long_time);
          
            if (timeA < timeB) {
              return -1;
            } else if (timeA > timeB) {
              return 1;
            } else {
              return 0;
            }
          }
          uniqueArrayOfObjects.sort(compareTimes);
          
          return (
            <Box
              p="1%"
              mb="1%"
              width="49%"
              bgcolor={"#fff"}
              boxShadow="0px 0px 5px rgba(176, 176, 176, 0.1)"
              borderRadius={5}
            >
              <Box display="flex" gap={3} alignItems="start">
                <BoltIcon
                  sx={{
                    borderRadius: "50%",
                    bgcolor: "rgba(217, 217, 217, 0.2)",
                    padding: 0.5,
                  }}
                />
                <Box width="100%">
                  <div className="grid grid-cols-1 sm:grid-cols-2">
                    <div>
                      <Box display="flex" gap={1}>
                        <Typography
                          fontSize={14}
                          fontWeight={500}
                          color="rgba(0, 0, 0, 0.7)"
                        >

                          {setChartTitle(data[data.length - 1])}
                        </Typography>
                        <Tooltip
                          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                          arrow
                          placement="right"
                        >
                          <PriorityHighIcon
                            sx={{
                              borderRadius: "50%",
                              bgcolor: "#E6E6E6",
                              padding: 0.2,
                              color: "white",
                              fontSize: "13px",
                            }}
                          />
                        </Tooltip>
                      </Box>
                      <Typography
                        fontSize={11}
                        fontWeight={300}
                        color="#8D8D8D"
                      >
                        as of {setChartTime(data[data.length - 1])}
                      </Typography>
                    </div>
                  </div>
                  <div className=" mt-5 mb-5">
                    <Box display="flex" justifyContent="space-between" gap={2}>
                      {/* <Box className="col-start-1 col-span-2  gap-2">
                                            <Typography
                                                component="span"
                                                fontSize={10}
                                                fontWeight={700}
                                                color="#971BF9"
                                            >
                                                75.33/100.33 kW
                                            </Typography>
                                        </Box> */}
                      <Box>
                        <Box>
                          <Box display="flex" gap={1}>
                            <Typography
                              fontSize={24}
                              fontWeight={700}
                              color="#971BF9"
                            >

                              {/* {data[data.length - 1]?.csv_expVal < 1 ? (
                                `${data[data.length - 1]?.csv_expVal * 1000}W`
                              ) : ( */}
                              <React.Fragment>
                                {
                                  data[data.length - 1]?.csv_expVal
                                    ?.toFixed(2)
                                    ?.toString()
                                    ?.split(".")[0]
                                }
                                <Typography
                                  component="span"
                                  fontSize={17}
                                  fontWeight={600}
                                  color="#971BF9"
                                  className="Mediumlg:text-[13px]"
                                >
                                  .
                                  {
                                    data[data.length - 1]?.csv_expVal
                                      ?.toFixed(2)
                                      ?.toString()
                                      ?.split(".")[1]
                                  }
                                  {/* kW */}
                                </Typography>
                              </React.Fragment>
                              {/* )} */}

                              {/* {Number(projectChartData.uv ? projectChartData.uv : 0).toFixed(2)?.toString()?.split(".")[0]}
                                                        <Typography
                                                            component="span"
                                                            fontSize={12}
                                                            fontWeight={600}
                                                            color="#971BF9"
                                                        >
                                                            .{Number(projectChartData.uv ? projectChartData.uv : 0).toFixed(2)?.toString()?.split(".")[1]}
                                                            {(projectChartData?.uv > 1000 ? "W" : "kW")}
                                                        </Typography> */}
                            </Typography>
                            {/* <Box>
                                                        <Typography
                                                            color="#07D2B4"
                                                            fontWeight={400}
                                                            fontSize={10}
                                                        >
                                                            -7%
                                                            <TrendingDownIcon fontSize="10px" />
                                                        </Typography>
                                                        <Typography
                                                            color="#8D8D8D"
                                                            fontWeight={300}
                                                            fontSize={8}
                                                        >
                                                            vs previous 30 days
                                                        </Typography>
                                                    </Box>
                                                    <Tooltip
                                                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                                                        arrow
                                                        placement="right"
                                                    >
                                                        <PriorityHighIcon
                                                            sx={{
                                                                borderRadius: "50%",
                                                                bgcolor: "#E6E6E6",
                                                                padding: 0.2,
                                                                color: "white",
                                                                fontSize: "13px"
                                                            }}
                                                        />
                                                    </Tooltip> */}
                          </Box>
                        </Box>
                        {/* <Box>
                                                <Box display="flex" gap={1}>
                                                    <Typography
                                                        fontSize={24}
                                                        fontWeight={700}
                                                        color="#FFBC0F"
                                                    >
                                                        55
                                                        <Typography
                                                            component="span"
                                                            color="#FFBC0F80"
                                                        >
                                                            kWh
                                                        </Typography>
                                                    </Typography>
                                                    <Box>
                                                        <Typography
                                                            color="#07D2B4"
                                                            fontWeight={400}
                                                            fontSize={10}
                                                        >
                                                            -7%
                                                            <TrendingDownIcon fontSize="10px" />
                                                        </Typography>
                                                        <Typography
                                                            color="#8D8D8D"
                                                            fontWeight={300}
                                                            fontSize={8}
                                                        >
                                                            vs previous 30 days
                                                        </Typography>
                                                    </Box>
                                                    <Tooltip
                                                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                                                        arrow
                                                        placement="right"
                                                    >
                                                        <PriorityHighIcon
                                                            sx={{
                                                                borderRadius: "50%",
                                                                bgcolor: "#E6E6E6",
                                                                padding: 0.2,
                                                                color: "white",
                                                                fontSize: "13px"
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            </Box> */}
                      </Box>

                      {/* <Box className="col-start-1 col-span-2  gap-2">
                                            <Typography
                                                component="span"
                                                fontSize={10}
                                                fontWeight={700}
                                                color="#FFBC0F"
                                            >
                                                75.33/100.33 kW
                                            </Typography>
                                        </Box> */}

                      <Box>
                        <CSVExportBtn
                          headers={csvHeader}
                          csvData={
                            data ? data : []
                          }
                          label={
                            <FileDownloadOutlinedIcon
                              sx={{
                                padding: 0.5,
                                color: "#808080d1",
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          }
                          prifix="_total_power"
                        />
                        <OpenInFullOutlinedIcon
                          sx={{
                            padding: 0.5,
                            color: "#808080d1",
                          }}
                          style={{ cursor: "pointer" }}
                          onClick={() => expandGraph()}
                        />
                      </Box>
                    </Box>
                  </div>
                </Box>
              </Box>

              <ResponsiveContainer width="100%" height="70%">
                <AreaChart
                  data={uniqueArrayOfObjects}
                  className={"total-chart-container"}
                  margin={{
                    top: 5,
                    right: 55,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <defs>
                    <linearGradient id="colorAreaChart" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor="rgba(151, 27, 249, 1)"
                        stopOpacity={0.1}
                      />
                      <stop
                        offset="95%"
                        stopColor="rgba(151, 27, 249, 0.09)"
                        stopOpacity={0.1}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="name"
                    fontSize={14}
                    fontWeight={400}
                    tick={{ fill: "#BEBEBE" }}
                    interval={72}
                    tickLine={false}
                    axisLine={false}
                  >
                    <Label
                      offset={-4}
                      value="Time"
                      position="insideBottom"
                      fontSize={11}
                      fill="#616161"
                      fontWeight={300}
                    />
                  </XAxis>
                  <YAxis
                    fontSize={14}
                    fontWeight={400}
                    tick={{ fill: "#971BF9" }}
                    tickLine={false}
                    axisLine={false}
                    label={{
                      value: setChartTitle(data[data.length - 1]),
                      angle: -90,
                      fontWeight: 300,
                      fontSize: 11,
                      offset: -4,
                      fill: "#616161",
                      height: "100%",
                      position: "insideLeft",
                    }}
                  />
                  <ReChartToolTip
                    wrapperStyle={{ outline: "none" }}
                    cursor={false}
                    content={<CustomToolTipContent color="#971BF9" />}
                  />
                  <CartesianGrid vertical={false} stroke="#DDD" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="csv_expVal"
                    stroke="rgba(151, 27, 249, 1)"
                    fillOpacity={1}
                    fill="url(#colorAreaChart)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default EquipmentDetail;
