export const Routes = {
  emptyPath: "/",
  login: "/login",
  dashboard: "/dashboard",
  forgotPassword: "/forgot-password",
  userDetail: "/user-detail",
  people: "/users",
  addUser: "/users/add",
  editUser: "/users/edit",
  equipments: "/equipments",
  viewUser: "/users/view",
  equipmentsDetail: "/equipment/view",
  viewChart: "/chart/view",
  fullViewChart: "/chart/full-view",
  addEquipment: "/equipment/add",
  editEquipment: "/equipment/edit",
  moveEquipment: "/equipment/move",
  createGroup: "/create-group",
  viewMap: "/map/view",
  deviceProfile: "/devices",
  addDevice: "/devices/add",
  editDevice: "/devices/edit",
  equipmentSettings: "/alert"
}