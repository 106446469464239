const Permissions = {

    'SUPER_ADMIN': ['DASHBOARD', 'EQUIPMENTS', 'USERS', 'DEVICES', 'Alert'],

    'ADMIN': ['DASHBOARD', 'EQUIPMENTS', 'USERS', 'DEVICES', 'Alert'],

    'ITEGRATOR': ['DASHBOARD', 'EQUIPMENTS', 'DEVICES', 'Alert'],

    'OPERATOR': ['DASHBOARD', 'EQUIPMENTS'],

}

export default Permissions;



export const ROLE = {

    100: "SUPER_ADMIN",
    99: "ADMIN",
    98: "ITEGRATOR",
    97: "OPERATOR",
    
}