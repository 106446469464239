import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  Grid,
  DialogContent,
  DialogActions,
} from "@mui/material";

const RemoveAlertModal = ({
  open,
  id,
  alertId,
  handleDeleteClose,
  handleAlertDelete,
}) => {
  const [{ openConfirm, openDeleteConfirm, text }, setRemoveState] = useState({
    openConfirm: open,
    openDeleteConfirm: false,
    text: "",
  });

  const handleTextChange = (value) => {
    setRemoveState((oldState) => ({
      ...oldState,
      text: value,
    }));
  };
  const handleDeleteConfirm = () => {
    setRemoveState((oldState) => ({
      ...oldState,
      openConfirm: false,
      openDeleteConfirm: true,
    }));
  };

  const handleSubmitDeleteConfirm = () => {
    if (text && text === "DELETE") {
      setRemoveState((oldState) => ({
        ...oldState,
        openConfirm: false,
        openDeleteConfirm: false,
        text: "",
      }));
      handleDeleteClose();
      handleAlertDelete(id);
    } else {
      return;
    }
  };

  const ALERT_LABEL = alertId ? `“${alertId}”` : "";
  const ConfirmLayout = () => {
    return (
      <Box className="remove-equipment-div">
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            display: "flex",
            flexDirection: "row",
            fontWeight: 600,
            fontSize: 24,
            lineHeight: "35px",
            color: "#2D3748",
            width: "40rem",
          }}
        >
          Are you sure you want to remove the alert {ALERT_LABEL} ? {"  "}
        </Typography>
        <Typography
          variant="overline"
          display="block"
          gutterBottom
          sx={{
            fontWeight: 400,
            fontSize: 18,
            lineHeight: "31px",
            color: "#2D3748",
          }}
        >
          This action can’t be undone
        </Typography>
      </Box>
    );
  };

  const ConfirmDeleteLayout = ({ text, handleTextChange }) => {
    const handleChangeInput = (e) => {
      e.preventDefault();
      handleTextChange(e.target.value);
    };

    return (
      <Box className="remove-equipment-confirm-div">
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: 600, fontSize: 24, lineHeight: "35px" }}
        >
          Type “DELETE” in the box below to confirm.
        </Typography>
        <div className="eqipment-form" style={{ width: "80%" }}>
          <div className="eq-form-top" style={{ margin: "10px 0 50px 0" }}>
            <div className="input-control-main">
              <div className="control">
                <input
                  type="text"
                  key="text-field"
                  id="text-field"
                  autoFocus="autoFocus"
                  name="text"
                  value={text || ""}
                  placeholder="Insert Text Here"
                  onChange={handleChangeInput}
                  className={"custom-input-control"}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Dialog
        onClose={handleDeleteClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="filter-modal"
      >
        <DialogContent>
          {openConfirm ? (
            <ConfirmLayout />
          ) : (
            <ConfirmDeleteLayout
              text={text}
              handleTextChange={handleTextChange}
            />
          )}
        </DialogContent>
        <DialogActions className="filter-footer" style={{ display: "block" }}>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={6}>
              <Button
                onClick={
                  openConfirm ? handleDeleteConfirm : handleSubmitDeleteConfirm
                }
                disabled={
                  openConfirm ? false : openDeleteConfirm && text ? false : true
                }
                class="filterConditionSaveBtn filter-footer-btn"
                style={{ cursor: "pointer", border: "unset" }}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                class="filterConditionCancleBtn filter-footer-btn"
                onClick={handleDeleteClose}
                style={{ cursor: "pointer", border: "unset" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default RemoveAlertModal;
