export const NodeListSuccess = 'NODE_LIST_SUCCESS';
export const InstEnergySuccess = 'INSTENERGY_LIST_SUCCESS';
export const HistoryProjectListSuccess = 'GET_HISTORY_PROJECT_LIST';
export const ConsumptionPatternSuccess = 'GET_CONSUMPTION_PATTERN';
export const ConsumptionSelectedDateSuccess = 'GET_CONSUMPTION_DATE';
export const ConsumptionSelectedMonthSuccess = 'GET_CONSUMPTION_MONTH';
export const NodeListParamSuccess = 'NODE_LIST_PARAM';
export const DeviceInstParamSuccess = 'DEVICE_INST_PARAM';
export const ClearDashboardData = 'CLEAR_DASHBOARD_DATA';
export const DeviceTHDSuccess = 'DEVICE_THD';
export const ProjectListChartSuccess = 'PROJECTLISTCHART_DATA';
export const SET_AUTO_REFRESH_DASHBOARD = 'SET_AUTO_REFRESH_DASHBOARD';
export const SET_PROJECT_PARAM = 'SET_PROJECT_PARAM';
export const GlobalAlertListSuccess = 'GLOBAL_ALERT_LIST';
export const NodeAlertListSuccess = 'Node_ALERT_LIST';
export const GET_TARGET_ALERT = 'GET_TARGET_ALERT';
export const GetNodeTargetAlert = 'GET_NODE_TARGET_ALERT'





export function setNodeListAction(data) {
  return {
    type: NodeListSuccess,
    payload: data
  }
}

export function setInstEnergyAction(data) {
  return {
    type: InstEnergySuccess,
    payload: data
  }
}

export function setHistoryProjectListAction(data) {
  return {
    type: HistoryProjectListSuccess,
    payload: data
  }
}

export function setConsumptionPatternAction(data) {
  return {
    type: ConsumptionPatternSuccess,
    payload: data
  }
}


export function setConsumptionChartSelectedDateAction(data) {
  return {
    type: ConsumptionSelectedDateSuccess,
    payload: data
  }
}

export function setConsumptionChartSelectedMonthAction(data) {
  return {
    type: ConsumptionSelectedMonthSuccess,
    payload: data
  }
}



export function setNodeListParamAction(data) {
  return {
    type: NodeListParamSuccess,
    payload: data
  }
}

export function setGlobalAlertListAction(data){
  return {
    type: GlobalAlertListSuccess,
    payload: data
  }
}

export function setTargetAlertAction(data){
  return {
    type: GET_TARGET_ALERT,
    payload: data
  }
}

export function setNodeTargetAlertAction(data){
  return {
    type: GetNodeTargetAlert,
    payload: data
  }
}

export function setNodeAlertListAction(data){
  return {
    type: NodeAlertListSuccess,
    payload: data
  }
}

export function setDeviceInstParamAction(data) {
  return {
    type: DeviceInstParamSuccess,
    payload: data
  }
}

export function setDeviceTHDAction(data) {
  return {
    type: DeviceTHDSuccess,
    payload: data
  }
}

export function setClearDashboard() {
  return {
    type: ClearDashboardData,
  }
}

export function setCSVProjectListChartData(data) {
  return {
    type: ProjectListChartSuccess,
    payload: data
  }
}

export function setAutoRefreshDashboard(data) {
  return {
    type: SET_AUTO_REFRESH_DASHBOARD,
    payload: data
  }
}

export function setProjectParam(data) {
  return {
    type: SET_PROJECT_PARAM,
    payload: data
  }
}
